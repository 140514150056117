import { useEffect, useRef, useState } from "react";
import { Dropdown, FluentProvider, Option, Radio, RadioGroup, webLightTheme } from "@fluentui/react-components";
import type { DropdownProps } from "@fluentui/react-components";

import styles from "./TemporaryFeature11.module.css";

interface Props {
    environment: any;
    dataIdRef: React.MutableRefObject<string>;
    methodIdRef: React.MutableRefObject<string>;
    searchTypeRef: React.MutableRefObject<string>;
}

export const TemporaryFeature11 = ({ environment, dataIdRef, methodIdRef, searchTypeRef }: Props, props: Partial<DropdownProps>) => {
    if (dataIdRef.current.length === 0) {
        dataIdRef.current = "1";
    }
    if (methodIdRef.current.length === 0) {
        methodIdRef.current = "1";
    }
    if (searchTypeRef.current.length === 0) {
        searchTypeRef.current = "product";
    }

    const documentData = [
        ["1", "AZPower 就業規則"],
        ["2", "AZPower 業務マニュアル"],
        ["3", "PowerSKILL 操作マニュアル"]
    ];

    const [optionValue, setOptionValue] = useState<string>(documentData[0][1]);
    const [methodId, setMethodId] = useState<string>(methodIdRef.current ?? "1");
    const [searchType, setSearchType] = useState<string>(searchTypeRef.current ?? "regular");
    const [selectedOptions, setSelectedOptions] = useState<string[]>(["1"]);

    const onOptionSelect: (typeof props)["onOptionSelect"] = (ev, data) => {
        dataIdRef.current = data.selectedOptions[0];
        setSelectedOptions(data.selectedOptions);
        setOptionValue(documentData[Number(dataIdRef.current) - 1][1]);
        log_debug(new Date().toISOString(), data);
    };

    const handleChangeMethodNum = (e: any, data: any) => {
        methodIdRef.current = data.value || "1";
        setMethodId(methodIdRef.current);
        log_debug(new Date().toISOString(), `methodId: ${methodIdRef.current}`);
    };

    const handleChangeSearchType = (e: any, data: any) => {
        searchTypeRef.current = data.value || "regular";
        setSearchType(searchTypeRef.current);
        log_debug(new Date().toISOString(), `searchTypeRef: ${searchTypeRef.current}`);
    };

    const log_debug = (datetime: string, message: any) => {
        if (environment.consoleLogOutput) console.debug(datetime, message);
    };

    useEffect(() => {
        log_debug(new Date().toISOString(), `dataId: ${dataIdRef.current}`);
        log_debug(new Date().toISOString(), `methodId: ${methodIdRef.current}`);
        log_debug(new Date().toISOString(), `searchType: ${searchTypeRef.current}`);
    }, []);

    useEffect(() => {
        setMethodId(methodIdRef.current);
        log_debug(new Date().toISOString(), `useEffect - methodId: ${methodIdRef.current}`);
    }, [methodIdRef.current]);

    useEffect(() => {
        setSelectedOptions([dataIdRef.current]);
        setOptionValue(documentData[Number(dataIdRef.current) - 1][1]);
        log_debug(new Date().toISOString(), `useEffect - dataId: ${dataIdRef.current}`);
    }, [dataIdRef.current]);

    useEffect(() => {
        setSearchType(searchTypeRef.current);
        log_debug(new Date().toISOString(), `useEffect - searchType: ${searchTypeRef.current}`);
    }, [searchTypeRef.current]);

    return (
        <>
            {searchType == "product" && (
                <>
                    <div className={styles.dataTypeDropdownContainer}>
                        <FluentProvider className={styles.provider} theme={webLightTheme}>
                            <Dropdown
                                placeholder="データを選択してください"
                                size="medium"
                                //defaultSelectedOptions={["1"]}
                                //defaultValue={defaultValue}
                                value={optionValue}
                                selectedOptions={selectedOptions}
                                onOptionSelect={onOptionSelect}
                            >
                                {documentData.map(item => (
                                    <Option key={item[0]} value={item[0]}>
                                        {item[1]}
                                    </Option>
                                ))}
                            </Dropdown>
                        </FluentProvider>
                    </div>
                    <div className={styles.methodIdToggle}>
                        <RadioGroup className={styles.radioGroup} value={methodId} layout="horizontal" onChange={handleChangeMethodNum} aria-labelledby="">
                            <Radio className={styles.type1} value="1" label="" />
                            <Radio className={styles.type2} value="2" label="" />
                        </RadioGroup>
                        <div className={`${styles.circleSwith} ${methodId === "1" ? styles.circleLeft : styles.circleRight}`}></div>
                    </div>
                </>
            )}
            <div className={styles.searchTypeToggle}>
                <RadioGroup className={styles.radioGroup} value={searchType} layout="horizontal" onChange={handleChangeSearchType} aria-labelledby="">
                    <Radio className={styles.typeRegular} value="regular" label="" />
                    <Radio className={styles.typeProduct} value="product" label="" />
                </RadioGroup>
                <div className={`${styles.circleSwith} ${searchType === "regular" ? styles.circleLeft : styles.circleRight}`}></div>
            </div>
        </>
    );
};
