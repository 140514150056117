import { useEffect, useState } from "react";
import { Radio, RadioGroup } from "@fluentui/react-components";

import styles from "./SearchTypeToggle.module.css";

interface Props {
    environment: any;
    searchTypeRef: React.MutableRefObject<string>;
}

export const SearchTypeToggle = ({ environment, searchTypeRef }: Props) => {
    const [searchType, setSearchType] = useState<string>(searchTypeRef.current ?? environment.temporaryFeature1 ? "product" : "regular");

    const handleChange = (e: any, data: any) => {
        searchTypeRef.current = data.value || "regular";
        setSearchType(searchTypeRef.current);
    };

    const log_debug = (datetime: string, message: string) => {
        if (environment.consoleLogOutput) console.debug(datetime, message);
    };

    useEffect(() => {
        const searchType = environment.temporaryFeature1 ? "product" : "regular";
        setSearchType(searchType);
        searchTypeRef.current = searchType;
        log_debug(new Date().toISOString(), `searchType1: ${searchTypeRef.current}`);
    }, []);

    useEffect(() => {
        setSearchType(searchTypeRef.current);
        log_debug(new Date().toISOString(), `searchType2: ${searchTypeRef.current}`);
    }, [searchTypeRef.current]);

    return (
        <div className={styles.searchTypeToggle}>
            <RadioGroup className={styles.radioGroup} value={searchType} layout="horizontal" onChange={handleChange} aria-labelledby="">
                <Radio className={styles.typeRegular} value="regular" label="" />
                <Radio className={styles.typeProduct} value="product" label="" />
            </RadioGroup>
            <div className={`${styles.circleSwith} ${searchType === "regular" ? styles.circleLeft : styles.circleRight}`}></div>
        </div>
    );
};
