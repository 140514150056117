import { useEffect, useRef, useState } from "react";
import { Stack } from "@fluentui/react";
import { Delete28Filled, Image28Regular, Send28Filled } from "@fluentui/react-icons";
import { confirmAlert } from "react-confirm-alert";
import { PromptLibrary } from "../PromptLibrary";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { ErrorModal } from "../ErrorModal";

import styles from "./QuestionInput.module.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./QuestionInput.pure.css";

import * as CONST from "../../utils/constant";

interface Props {
    onSend: (question: string) => void;
    isLoading: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    environment: any;
    threadId: string;
    showAttachmentIcon: boolean;
    usId: string;
    onFileNamesMapChange: (fileNamesMap: Map<string, string>) => void;
}

export const QuestionInput = ({ onSend, placeholder, clearOnSend, isLoading, environment, threadId, showAttachmentIcon, usId, onFileNamesMapChange }: Props) => {
    const textAreaMinHeight = CONST.CSS_PROPS.QUESTION_INPUT_TEXT_AREA.MIN_HEIGHT;

    const [question, setQuestion] = useState<string>("");
    const [textAreaHeight, setTextAreaHeight] = useState<number>(textAreaMinHeight);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const handleSend = (question: string) => {
        setIsOpenPromptLibrary(false);

        if (textAreaRef.current) {
            textAreaRef.current.style.height = "unset";
        }

        onSend(question);
    };

    const sendQuestion = () => {
        if (isLoading || !question.trim()) {
            return;
        }

        if (question.length < 4000) {
            onFileNamesMapChange(fileNamesMap);
            handleSend(question);
            if (clearOnSend) {
                setQuestion("");
                setFiles([]);
                setFileNamesMap(new Map());
            }
        } else {
            confirmAlert({
                message: "入力したテキストは" + question.length + "文字です。\n長い文章は、複数回に分けて入力することを推奨します。\n\nこのまま送信しますか？",
                buttons: [
                    {
                        label: "送信する",
                        onClick: () => {
                            onFileNamesMapChange(fileNamesMap);
                            handleSend(question);

                            if (clearOnSend) {
                                setQuestion("");
                                setFiles([]);
                                setFileNamesMap(new Map());
                            }
                        }
                    },
                    {
                        label: "キャンセル",
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey && !isFileUploadInProgress) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (event: any) => {
        setPrompt(event.target.value);
    };

    const handleClickInsertPrompt = (prompt: string) => {
        setPrompt(prompt);
    };

    const setPrompt = (prompt: string) => {
        if (!prompt) {
            setQuestion("");
        } else {
            setQuestion(prompt);
        }
    };

    useEffect(() => {
        if (!textAreaRef.current) return;

        if (question) {
            if (textAreaRef.current.scrollHeight <= textAreaMinHeight) return;

            textAreaRef.current.style.height = "auto";
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
            textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
        } else {
            textAreaRef.current.style.height = "";
        }

        setTextAreaHeight(textAreaRef.current.scrollHeight);
    }, [question]);

    const sendQuestionDisabled = isLoading || !question.trim();

    //プロンプトLibraryの開閉
    const [isOpenPromptLibrary, setIsOpenPromptLibrary] = useState(false);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [files, setFiles] = useState<File[]>([]);
    const [refreshKey, setRefreshKey] = useState<number>(0);
    const [fileNamesMap, setFileNamesMap] = useState(new Map());

    useEffect(() => {
        onFileNamesMapChange(fileNamesMap);
    }, [fileNamesMap, onFileNamesMapChange]);

    useEffect(() => {
        if (files.length > 0) {
            setRefreshKey(prevKey => prevKey + 1);
        }
    }, [files]);

    const [isFileUploadInProgress, setIsFileUploadInProgress] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
    };
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const selectedFiles = Array.from(event.target.files);
            event.target.value = '';

            // 重複ファイルは表示リストに載せない
            const uniqueFiles = selectedFiles.filter(newFile =>
                !files.some(existingFile => existingFile.name === newFile.name)
            );

            // ファイルのアップロード
            setIsFileUploadInProgress(true);
            const uploadSuccess = await uploadFiles(selectedFiles);
            setIsFileUploadInProgress(false);
            if (uploadSuccess) {
                setFiles(currentFiles => [...currentFiles, ...uniqueFiles]);
            }
        }
    };

    const uploadFiles = async (filesToUpload: File[]) => {
        // 日時
        const now = new Date();
        const japanOffsetInMilliseconds = 9 * 60 * 60 * 1000;
        const japanTime = new Date(now.getTime() + japanOffsetInMilliseconds);
        const timestamp = japanTime.toISOString().replace(/[-T:.Z]/g, '').slice(0, 14);
        // ファイル名とファイル名_日時.拡張子のマップを作成
        const newFileNamesMap = new Map(fileNamesMap);

        const formData = new FormData();
        filesToUpload.forEach((file, index) => {
            formData.append(`files[${index}]`, file);

            // ファイル名作成
            const extension = file.name.split('.').pop(); // 拡張子を取得
            const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.'); // 拡張子を削除したファイル名
            const fileNameAddTime = `${fileNameWithoutExtension}_${timestamp}.${extension}`; // ファイル名_日時.拡張子
            formData.append(`fileNames[${index}]`, fileNameAddTime);

            newFileNamesMap.set(file.name, fileNameAddTime);
        });

        formData.append('threadId', threadId);
        formData.append('userId', usId);
        try {
            const response = await fetch('/fileUpload', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                console.log('Files uploaded successfully');
                // 更新されたマップをセット
                setFileNamesMap(newFileNamesMap);
                return true;
            } else {
                setShowErrorModal(true);
                console.error('Failed to upload files');
                console.error(response.status);
                return false;
            }

        } catch (error) {
            setShowErrorModal(true);
            console.error('Error uploading files:', error);
            return false;
        }
    };

    const handleRemoveFile = async (fileToRemove: File) => {
        setFiles(currentFiles => currentFiles.filter(file => file !== fileToRemove));
        // 削除されたファイル名をマップから除外
        const updatedFileNamesMap = new Map(fileNamesMap);
        updatedFileNamesMap.delete(fileToRemove.name);
        setFileNamesMap(updatedFileNamesMap);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setQuestion(event.target.value);
    };
    const insertTextAtCursor = (insertText: string) => {
        const textarea = textAreaRef.current;
        if (!textarea) return;

        const startPos = textarea.selectionStart;
        const endPos = textarea.selectionEnd;
        const textBefore = question.substring(0, startPos);
        const textAfter = question.substring(endPos, question.length);

        const newQuestion = textBefore + insertText + textAfter;
        setQuestion(newQuestion);

        // カーソルの位置を挿入した文字列の末尾に設定
        const newCursorPos = startPos + insertText.length;
        setTimeout(() => {
            textarea.selectionStart = textarea.selectionEnd = newCursorPos;
            textarea.focus();
        }, 0);
    };

    return (
        <>
            {environment.usePromptLibrary && (
                <PromptLibrary
                    onClickUseThisPrompt={insertTextAtCursor}
                    isOpenPromptLibrary={isOpenPromptLibrary}
                    setIsOpenPromptLibrary={setIsOpenPromptLibrary}
                    textAreaHeight={textAreaHeight}
                />
            )}
            <div className={styles.questionInput}>
                <div className={styles.indicator}>{isLoading && <LoadingIndicator />}</div>
                <Stack horizontal className={styles.questionInputContainer}>
                    <div className={styles.questionInputTextArea}>
                        <textarea
                            ref={textAreaRef}
                            value={question}
                            placeholder={placeholder}
                            onChange={handleInputChange}
                            onKeyDown={onEnterPress}
                            style={{ paddingLeft: showAttachmentIcon ? '12px' : '40px' }}
                        />
                        {!showAttachmentIcon && (
                            <div key={refreshKey} className={styles.attachInputButtonsContainer}>
                                <label htmlFor="file-input" className={`file-input-label ${isFileUploadInProgress ? styles.disabled : ''}`}>
                                    <Image28Regular />
                                </label>
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    id="file-input"
                                    multiple
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    disabled={isFileUploadInProgress}
                                />
                            </div>
                        )}
                    </div>
                    <div className={styles.questionInputButtonsContainer}>
                        <div
                            className={`${styles.questionInputSendButton} ${(sendQuestionDisabled || isFileUploadInProgress) ? styles.questionInputSendButtonDisabled : ''
                                }`}
                            onClick={(!isFileUploadInProgress) ? sendQuestion : undefined}
                        >
                            <Send28Filled primaryFill="rgba(115, 118, 225, 1)" />
                        </div>
                    </div>
                </Stack>
                {!showAttachmentIcon && isFileUploadInProgress && <div>ファイルアップロード中...</div>}
                {!showAttachmentIcon && files.length > 0 && (
                    <ul style={{ listStyleType: 'none', overflowY: files.length > 5 ? 'scroll' : 'hidden', maxHeight: '200px' }}>
                        {files.map((file, index) => (
                            <li key={index}>
                                <label htmlFor={`delete-file-${index}`} className={styles.deleteFileLabel}>
                                    <Delete28Filled />
                                </label>
                                <input
                                    type="checkbox"
                                    id={`delete-file-${index}`}
                                    style={{ display: 'none' }}
                                    onClick={() => handleRemoveFile(file)}
                                />
                                {file.name}
                            </li>
                        ))}
                    </ul>
                )}
                {showErrorModal && <ErrorModal onClose={handleCloseErrorModal} />}
            </div>
        </>
    );
};
