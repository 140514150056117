import { useState } from "react";
import { Button } from "@fluentui/react-components";
import { ThumbDislike16Regular } from "@fluentui/react-icons";

import styles from "./AnswerButton.module.css";

interface Props {
    checked: boolean;
    onClick: () => Promise<boolean>;
}

export const DislikeButton = ({ checked, onClick }: Props) => {
    const [isDislikeClicked, setIsDislikeClicked] = useState<boolean>(checked);
    const buttonClassName = isDislikeClicked ? styles.buttonClicked : styles.button;

    return (
        <>
            <Button
                className={buttonClassName}
                icon={<ThumbDislike16Regular />}
                onClick={async () => {
                    const result = await onClick();
                    if (result) {
                        setIsDislikeClicked(true);
                    }
                }}
                disabled={isDislikeClicked}
            ></Button>
        </>
    );
};
