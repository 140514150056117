import { useEffect, useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import {
    Avatar,
    Button,
    Dialog,
    DialogOpenChangeData,
    FluentProvider,
    Menu,
    MenuList,
    MenuPopover,
    MenuProps,
    MenuTrigger,
    Text,
    webDarkTheme,
    webLightTheme
} from "@fluentui/react-components";
import {
    Delete20Regular,
    Delete20Filled,
    Open20Regular,
    Open20Filled,
    Settings20Regular,
    Settings20Filled,
    SignOut20Regular,
    SignOut20Filled,
    TextBoxSettings20Regular,
    TextBoxSettings20Filled,
    bundleIcon,
    TextAlignJustify20Regular
} from "@fluentui/react-icons";
import { List } from "react-bootstrap-icons";

import { InfoCircleFill, QuestionCircleFill } from "react-bootstrap-icons";
import { DialogClearChatHistory } from "../MenuDialog/DialogClearChatHistory";
import { DialogCustomInstruction } from "../MenuDialog/DialogCustomInstruction";
import { DialogSetting } from "../MenuDialog/DialogSetting";
import * as CONST from "../../utils/constant";

import styles from "./MenuButton.module.css";

const ClearIcon = bundleIcon(Delete20Filled, Delete20Regular);
const OpenIcon = bundleIcon(Open20Filled, Open20Regular);
const SettingsIcon = bundleIcon(Settings20Filled, Settings20Regular);
const SignOutIcon = bundleIcon(SignOut20Filled, SignOut20Regular);
const CustomInstructionIcon = bundleIcon(TextBoxSettings20Filled, TextBoxSettings20Regular);

interface MyProps {
    className: string;
}

const MyAvatar = ({ className }: MyProps) => {
    const { accounts } = useMsal();
    const name = (accounts && accounts[0] && accounts[0].name) ?? "";
    return <Avatar className={`${className ?? ""}`} name={name} />;
};

const MyName = ({ className }: MyProps) => {
    const { accounts } = useMsal();
    const name = (accounts && accounts[0] && accounts[0].name) ?? "";
    return <Text className={`${className ?? ""}`}>{name}</Text>;
};

interface ButtonProps {
    environment: any;
    onClearConversationsClicked: () => void;
    onClickInfo: () => void;
    onClickHelp: () => void;
    threadLength: number;
}

export const MenuButton = ({ environment, onClearConversationsClicked, onClickInfo, onClickHelp, threadLength }: ButtonProps) => {
    const triggerRef = useRef<HTMLButtonElement>(null);

    const [isOpenDialog11, setIsOpenDialog11] = useState(false);
    const [isOpenDialog12, setIsOpenDialog12] = useState(false);
    const [isOpenDialog2, setIsOpenDialog2] = useState(false);
    const [closeAction, setCloseAction] = useState<DialogOpenChangeData["type"] | null>(null);

    const [menuOpen, setMenuOpen] = useState(false);
    const onOpenChange: MenuProps["onOpenChange"] = (e, data) => {
        setMenuOpen(data.open);
    };

    useEffect(() => {
        // Prevents focusing on an initial render
        if (isOpenDialog11 || isOpenDialog12 || isOpenDialog2 || closeAction === null) {
            return;
        }
        triggerRef.current?.focus();
    }, [closeAction, isOpenDialog11, isOpenDialog12, isOpenDialog2]);

    const { instance, accounts } = useMsal();
    const accountId = (accounts && accounts[0] && accounts[0].localAccountId) ?? "";

    const handleLogout = (logoutType: string) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/"
            });
        }
    };

    return (
        <div className={styles.accountBlock}>
            {environment.useInformation && (
                <>
                    <InfoCircleFill className={styles.infoCircleFill} onClick={onClickInfo} />
                </>
            )}
            {environment.useHelp && (
                <>
                    <QuestionCircleFill className={styles.questionCircleFill} onClick={onClickHelp} />
                </>
            )}
            <FluentProvider theme={webDarkTheme} className={styles.userInfo}>
                <div className={styles.userInfoWrapper}>
                    <MyAvatar className={styles.myAvatar} />
                    <MyName className={styles.myName} />
                </div>
            </FluentProvider>
            <FluentProvider theme={webLightTheme} className={styles.menuButton}>
                <Menu open={menuOpen} onOpenChange={onOpenChange}>
                    <MenuTrigger disableButtonEnhancement>
                        <Button size="large" appearance="subtle">
                            {/*<TextAlignJustify20Regular />*/}
                            <List className={styles.dropup} />
                        </Button>
                    </MenuTrigger>
                    <MenuPopover className={styles.menuPopover}>
                        <MenuList className={styles.menuList}>
                            {environment.useCustomInstruction && (
                                <Button
                                    className={styles.settingsButton}
                                    icon={<CustomInstructionIcon />}
                                    onClick={() => {
                                        setIsOpenDialog2(true);
                                        setCloseAction(null);
                                        setMenuOpen(false);
                                    }}
                                >
                                    {CONST.MENU.CUSTOM_INSTRUCTIONS}
                                </Button>
                            )}
                            {environment.useRemoveThreadHistory && (
                                <Button
                                    className={styles.settingsButton}
                                    icon={<ClearIcon />}
                                    onClick={() => {
                                        onClearConversationsClicked();
                                        setMenuOpen(false);
                                    }}
                                >
                                    {CONST.MENU.CLEAR_CONVERSATIONS}
                                </Button>
                            )}
                            {environment.useErrorReport && (
                                <Button
                                    className={styles.settingsButton}
                                    icon={<OpenIcon />}
                                    onClick={() => {
                                        window.open(environment.reportFormUri, "_blank");
                                        setMenuOpen(false);
                                    }}
                                >
                                    不具合・課題管理フォーム
                                </Button>
                            )}
                            {environment.useSettings && (
                                <Button
                                    className={styles.settingsButton}
                                    icon={<SettingsIcon />}
                                    onClick={() => {
                                        setIsOpenDialog11(true);
                                        setCloseAction(null);
                                        setMenuOpen(false);
                                    }}
                                    ref={triggerRef}
                                >
                                    {CONST.MENU.SETTINGS}
                                </Button>
                            )}
                            <Button className={styles.settingsButton} icon={<SignOutIcon />} onClick={() => handleLogout("redirect")} ref={triggerRef}>
                                {CONST.MENU.SIGN_OUT}
                            </Button>
                        </MenuList>
                    </MenuPopover>
                </Menu>
            </FluentProvider>

            <Dialog
                open={isOpenDialog11}
                onOpenChange={(event, data) => {
                    setIsOpenDialog11(data.open);
                    setCloseAction(data.type);
                }}
            >
                <DialogSetting setIsOpen={setIsOpenDialog11} setIsOpenNext={setIsOpenDialog12} threadLength={threadLength} />
            </Dialog>

            <Dialog
                open={isOpenDialog12}
                onOpenChange={(event, data) => {
                    setIsOpenDialog12(data.open);
                    setCloseAction(data.type);
                }}
            >
                <DialogClearChatHistory setIsOpen={setIsOpenDialog12} onClickClearChatHistory={onClearConversationsClicked} />
            </Dialog>

            <Dialog
                open={isOpenDialog2}
                onOpenChange={(event, data) => {
                    setIsOpenDialog2(data.open);
                    setCloseAction(data.type);
                }}
            >
                <DialogCustomInstruction userId={accountId} setIsOpen={setIsOpenDialog2} />
            </Dialog>
        </div>
    );
};
