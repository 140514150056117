import { useState } from "react";
import { ClipboardButton } from "./ClipboardButton";
import { DislikeButton } from "./DislikeButton";
import { LikeButton } from "./LikeButton";
import { AnswerEvaluateRequest, AnswerEvaluateResponse, answerEvaluateApi } from "../../../api";
import * as CONST from "../../../utils/constant";

import styles from "./AnswerButton.module.css";

interface Props {
    threadId: string;
    index: number;
    answer: string;
    environment: any;
    evaluate: number;
    modelName: string;
}

export const AnswerButton = ({ threadId, index, answer, environment, evaluate, modelName }: Props) => {
    const [isLikeClicked, setIsLikeClicked] = useState<boolean>(evaluate === CONST.ANSWER_EVALUATE.LIKE);
    const [isDislikeClicked, setIsDislikeClicked] = useState<boolean>(evaluate === CONST.ANSWER_EVALUATE.DISLIKE);

    const onClickLike = async () => {
        const result = await callApiAnswerEvaluate(index, CONST.ANSWER_EVALUATE.LIKE);
        if (result && result.status === 200) {
            setIsLikeClicked(true);
        }
        return result && result.status === 200 ? true : false;
    };

    const onClickDislike = async () => {
        const result = await callApiAnswerEvaluate(index, CONST.ANSWER_EVALUATE.DISLIKE);
        if (result && result.status === 200) {
            setIsDislikeClicked(true);
        }
        return result && result.status === 200 ? true : false;
    };

    const callApiAnswerEvaluate = async (historyIndex: number, evaluate: number) => {
        // エラー処理を検討しよう
        try {
            const request: AnswerEvaluateRequest = {
                threadId: threadId,
                historyIndex: historyIndex,
                evaluate: evaluate
            };
            const result = await answerEvaluateApi(request);

            const answerEvaluate: AnswerEvaluateResponse = {
                status: result.status,
                error: result.error ?? ""
            };
            return answerEvaluate;
        } catch (e) {
            alert("更新できませんでした。");
        } finally {
            //NOP
        }
    };

    return (
        <div className={styles.buttons}>
            {modelName && <span className={styles.modelLabel}>{modelName}</span>}
            <ClipboardButton answer={answer} />
            {environment.useAnswerButtons && (
                <>
                    {!isDislikeClicked && <LikeButton checked={evaluate === CONST.ANSWER_EVALUATE.LIKE} onClick={onClickLike} />}
                    {!isLikeClicked && <DislikeButton checked={evaluate === CONST.ANSWER_EVALUATE.DISLIKE} onClick={onClickDislike} />}
                </>
            )}
        </div>
    );
};
