import { useMemo } from "react";
import { Stack } from "@fluentui/react";
import DOMPurify from "dompurify";

import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

import { Marked } from "marked";
import { markedHighlight } from "marked-highlight";
import hljs from "highlight.js";

import SyntaxHighlighter from "react-syntax-highlighter/dist/cjs/default-highlight";
import { nightOwl } from "react-syntax-highlighter/dist/cjs/styles/hljs";

import { AnswerButton } from "./answer-buttons/AnswerButton";
import { AnswerIcon } from "./AnswerIcon";
import { CodeCopyButton } from "./code-copy-buttons/CodeCopyButton";
import { GptVersions } from "../../utils/GptVersions";
import { parseAnswerToHtml } from "./AnswerParser";

import styles from "./Answer.module.css";
import "./Answer.pure.css";

interface Props {
    threadId: string;
    index: number;
    answer: string;
    isSelected?: boolean;
    isLoading?: boolean;
    inProgress?: boolean;
    environment: any;
    modelName: string;
    evaluate: number;
    children?: React.ReactNode;
}

export const Answer = ({ threadId, index, answer, isSelected, isLoading, inProgress, environment, modelName, evaluate }: Props) => {
    const parsedAnswer = useMemo(() => parseAnswerToHtml(answer), [answer]);
    const marked = new Marked(
        markedHighlight({
            langPrefix: "hljs language-",
            highlight(code, lang) {
                const language = hljs.getLanguage(lang) ? lang : "plaintext";
                return hljs.highlight(code, { language }).value;
            }
        })
    );

    // OLD - 切り替えたのでしばらく様子見する
    const markedAnswer = marked.parse(parsedAnswer.answerHtml);
    const checkedAnswer = typeof markedAnswer === "string" ? markedAnswer : "";
    const sanitizedAnswer = DOMPurify.sanitize(checkedAnswer);
    const displayModelName = GptVersions.convertToDisplayString(modelName);

    // Current
    const plainAnswer = typeof parsedAnswer.answerHtml === "string" ? parsedAnswer.answerHtml : "";

    return (
        <div className={styles.answer}>
            <section className={`markdown-body ${styles.markDownWrapper}`}>
                <Stack className={styles.answerContainer} verticalAlign="space-between">
                    <Stack.Item>
                        <Stack horizontal horizontalAlign="space-between">
                            <AnswerIcon />
                            {/* OLD
                            <div
                                data-index={index}
                                className={`${styles.answerText} ${inProgress && styles.inProgress} asnwerDiv`}
                                dangerouslySetInnerHTML={{ __html: sanitizedAnswer }}
                            ></div>
                            */}
                            {/* Current */}
                            <Markdown
                                className={`answerMarkdown ${styles.answerText}`}
                                rehypePlugins={[rehypeRaw]}
                                remarkPlugins={[remarkGfm]}
                                components={{
                                    pre({ node, children, ...props }) {
                                        const element = children as React.ReactElement;
                                        const className = element.props.className || "";
                                        const code = element.props.children || "";
                                        const match = /language-(\w+)/.exec(className);
                                        const lang = match && match[1] ? match[1] : "";

                                        return (
                                            <>
                                                <pre className={`codeWrapper ${styles.codeWrapper}`}>
                                                    <CodeCopyButton lang={lang} code={code}>
                                                        {children}
                                                    </CodeCopyButton>
                                                    <div className={`codeBody ${styles.codeBody}`}>{children}</div>
                                                </pre>
                                            </>
                                        );
                                    },
                                    code({ node, className = "blog-code", children, ...props }) {
                                        let match = /language-(\w+)(:.+)/.exec(className || "");
                                        let lang = match && match[1] ? match[1] : "";
                                        const name = match && match[2] ? match[2].slice(1) : "";
                                        if (name === "") {
                                            match = /language-(\w+)/.exec(className || "");
                                            lang = match && match[1] ? match[1] : "";
                                        }
                                        return (
                                            <div>
                                                <SyntaxHighlighter
                                                    style={nightOwl}
                                                    language={lang}
                                                    CodeTag="code"
                                                    PreTag="span"
                                                    wrapLines={false}
                                                    useInlineStyles={false}
                                                >
                                                    {String(children).replace(/\n$/, "")}
                                                </SyntaxHighlighter>
                                            </div>
                                        );
                                    }
                                }}
                            >
                                {plainAnswer}
                            </Markdown>
                        </Stack>
                    </Stack.Item>
                    {!inProgress && (
                        <Stack.Item>
                            <Stack horizontal horizontalAlign="end">
                                <AnswerButton
                                    threadId={threadId}
                                    index={index}
                                    answer={answer}
                                    environment={environment}
                                    evaluate={evaluate}
                                    modelName={displayModelName}
                                />
                            </Stack>
                        </Stack.Item>
                    )}
                </Stack>
            </section>
        </div>
    );
};
