import {
    AnswerEvaluateRequest,
    AnswerEvaluateResponse,
    CustomInstructionGetRequest,
    CustomInstructionGetResponse,
    CustomInstructionUpdateRequest,
    CustomInstructionUpdateResponse,
    EnvironmentResponse,
    InformationGetResponse,
    LoginHistoryUpdateRequest,
    LoginHistoryUpdateResponse,
    PersonalSettingsGetResponse,
    PromptLibraryGetRequest,
    PromptLibraryGetResponse,
    ReleaseNoteResponse,
    ThreadDeleteRequest,
    ThreadDeleteResponse,
    ThreadDetailRequest,
    ThreadDetailResponse,
    ThreadListRequest,
    ThreadListResponse,
    ThreadRemoveRequest,
    ThreadRemoveResponse,
    ThreadUpdateRequest,
    ThreadUpdateResponse
} from "./models";
import { graphConfig } from "../auth/authConfig";

export async function getReleaseNoteApi(): Promise<ReleaseNoteResponse> {
    const response = await fetch("/releasenote", {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const parsedResponse: ReleaseNoteResponse = await response.json().catch(error => {
        throw Error("Frontend error occurs.");
    });

    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function getEnvironmentApi(): Promise<EnvironmentResponse> {
    const response = await fetch("/environment", {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const parsedResponse: EnvironmentResponse = await response.json().catch(error => {
        throw Error("Frontend error occurs.");
    });

    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function threadListApi(options: ThreadListRequest): Promise<ThreadListResponse> {
    const response = await fetch("/thread/list", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user_id: options.user_id,
            offset: options.offset,
            limit: options.limit
        })
    });

    const parsedResponse: ThreadListResponse = await response.json().catch(error => {
        throw Error("Frontend error occurs.");
    });

    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function threadDetailApi(options: ThreadDetailRequest): Promise<ThreadDetailResponse> {
    const response = await fetch("/thread/detail", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user_id: options.user_id,
            id: options.id
        })
    });

    const parsedResponse: ThreadDetailResponse = await response.json().catch(error => {
        throw Error("Frontend error occurs.");
    });

    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function threadUpdateApi(options: ThreadUpdateRequest): Promise<ThreadUpdateResponse> {
    const response = await fetch("/thread/update", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user_id: options.user_id,
            id: options.id,
            title: options.title
        })
    });

    const parsedResponse: ThreadUpdateResponse = await response.json().catch(error => {
        throw Error("Frontend error occurs.");
    });

    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function threadDeleteApi(options: ThreadDeleteRequest): Promise<ThreadDeleteResponse> {
    const response = await fetch("/thread/delete", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user_id: options.user_id
        })
    });

    const parsedResponse: ThreadDeleteResponse = await response.json().catch(error => {
        throw Error("Frontend error occurs.");
    });

    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function threadRemoveApi(options: ThreadRemoveRequest): Promise<ThreadRemoveResponse> {
    const response = await fetch("/thread/remove", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user_id: options.user_id,
            id: options.id
        })
    });

    const parsedResponse: ThreadRemoveResponse = await response.json().catch(error => {
        throw Error("Frontend error occurs.");
    });

    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function answerEvaluateApi(params: AnswerEvaluateRequest): Promise<AnswerEvaluateResponse> {
    const response = await fetch("/answer/evaluate", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id: params.threadId,
            index: params.historyIndex,
            evaluate: params.evaluate
        })
    });

    const parsedResponse: AnswerEvaluateResponse = await response.json().catch(error => {
        throw Error("Frontend error occurs.");
    });

    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function customInstructionUpdateApi(params: CustomInstructionUpdateRequest): Promise<CustomInstructionUpdateResponse> {
    const response = await fetch("/setting/custom_instruction/update", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user_id: params.userId,
            prior_information: params.priorInformation,
            output_method: params.outputMethod,
            enabled: params.enabled
        })
    });

    const parsedResponse: CustomInstructionUpdateResponse = await response.json().catch(error => {
        throw Error("Frontend error occurs.");
    });

    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function customInstructionGetApi(params: CustomInstructionGetRequest): Promise<CustomInstructionGetResponse> {
    const response = await fetch("/setting/custom_instruction/get", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user_id: params.userId
        })
    });

    const parsedResponse: CustomInstructionGetResponse = await response.json().catch(error => {
        throw Error("Frontend error occurs.");
    });

    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function promptLibraryGetApi(params: PromptLibraryGetRequest): Promise<PromptLibraryGetResponse> {
    const response = await fetch("/prompt/library/get", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user_id: params.userId
        })
    });

    const parsedResponse: PromptLibraryGetResponse = await response.json().catch(error => {
        throw Error("Frontend error occurs.");
    });

    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function getInformationApi(): Promise<InformationGetResponse> {
    const response = await fetch("/information/get", {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const parsedResponse: InformationGetResponse = await response.json().catch(error => {
        throw Error("Frontend error occurs.");
    });

    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function loginHistorynUpdateApi(params: LoginHistoryUpdateRequest): Promise<CustomInstructionUpdateResponse> {
    const payload =
        params.userPrincipalName.length > 0
            ? {
                  user_id: params.userId,
                  user_principal_name: params.userPrincipalName
              }
            : {
                  user_id: params.userId
              };

    const response = await fetch("/login_history/update", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
    });

    const parsedResponse: LoginHistoryUpdateResponse = await response.json().catch(error => {
        throw Error("Frontend error occurs.");
    });

    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function personalSettingsGetApi(userPrincipalName: string): Promise<PersonalSettingsGetResponse> {
    const response = await fetch("/setting/personal/get", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user_principal_name: userPrincipalName
        })
    });

    const parsedResponse: PersonalSettingsGetResponse = await response.json().catch(error => {
        throw Error("Frontend error occurs.");
    });

    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function callMsGraph(accessToken: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
