import { LogLevel } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { msalConfigB2C } from "./authConfigB2C";

const getMsalLogLevel = (environment: any) => {
    const logLevel = environment.msalLogLevel ?? "Info";
    let msalLogLevel = LogLevel.Trace;
    if (logLevel === "Error") {
        msalLogLevel = LogLevel.Error;
    } else if (logLevel === "Warning") {
        msalLogLevel = LogLevel.Warning;
    } else if (logLevel === "Info") {
        msalLogLevel = LogLevel.Info;
    } else if (logLevel === "Verbose") {
        msalLogLevel = LogLevel.Verbose;
    } else {
        msalLogLevel = LogLevel.Trace;
    }

    return msalLogLevel;
};

export const getMsalConfig = (environment: any) => {
    if (environment.authType === "Azure_AD_B2C") {
        msalConfigB2C.auth = {
            clientId: environment.clientId,
            authority: environment.authoritySignIn,
            knownAuthorities: [environment.authorityDomain],
            redirectUri: environment.redirectUri,
            postLogoutRedirectUri: "/",
            navigateToLoginRequestUrl: false
        };
        msalConfigB2C.system.loggerOptions.logLevel = getMsalLogLevel(environment);
        return msalConfigB2C;
    } else {
        msalConfig.auth = {
            clientId: environment.clientId,
            authority: environment.authority,
            redirectUri: environment.redirectUri
        };
        msalConfig.system.loggerOptions.logLevel = getMsalLogLevel(environment);
        return msalConfig;
    }
};
