import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import {
    Button,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Field,
    FluentProvider,
    Popover,
    PopoverProps,
    PopoverSurface,
    PopoverTrigger,
    Switch,
    Text,
    Textarea,
    TextareaProps,
    webLightTheme
} from "@fluentui/react-components";
import {
    CustomInstructionGetRequest,
    CustomInstructionGetResponse,
    CustomInstructionUpdateRequest,
    CustomInstructionUpdateResponse,
    customInstructionGetApi,
    customInstructionUpdateApi
} from "../../api";
import * as CONST from "../../utils/constant";

import styles from "./DialogCustomInstruction.module.css";

interface Props {
    userId: string;
    setIsOpen: (isOpen: boolean) => void;
}

export const DialogCustomInstruction = ({ userId, setIsOpen }: Props) => {
    const [priorInformation, setPriorInformation] = useState("");
    const [outputMethod, setOutputMethod] = useState("");
    const [enabled, setEnabled] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [isSwitchDisabled, setIsSwitchDisabled] = useState(true);

    const countPriorInformationRef = useRef<number>(0);
    const countOutputMethodRef = useRef<number>(0);

    const prevPriorInformationRef = useRef<string>("");
    const prevOutputMethodRef = useRef<string>("");
    const prevEnabledRef = useRef<boolean>(false);

    useEffect(() => {
        (async () => {
            const result = await callApiCustomInstructionGet();
            if (result && result.status === 200) {
                setPriorInformation(result.priorInformation);
                setOutputMethod(result.outputMethod);
                setEnabled(result.enabled);
                prevPriorInformationRef.current = result.priorInformation;
                prevOutputMethodRef.current = result.outputMethod;
                prevEnabledRef.current = result.enabled;
            }
        })();
    }, []);

    useEffect(() => {
        let priorInformationChanged = false;
        let outputMethodChanged = false;
        let enabledChanged = false;

        if (priorInformation.length == 0 && outputMethod.length == 0) {
            setIsSwitchDisabled(true);
            setEnabled(false);
        } else {
            setIsSwitchDisabled(false);
        }

        if (priorInformation !== prevPriorInformationRef.current) {
            priorInformationChanged = true;
        }

        if (outputMethod !== prevOutputMethodRef.current) {
            outputMethodChanged = true;
        }

        if (enabled !== prevEnabledRef.current) {
            enabledChanged = true;
        }

        setHasChanged(priorInformationChanged || outputMethodChanged || enabledChanged);
    }, [priorInformation, outputMethod, enabled]);

    const onChangePriorInformation: TextareaProps["onChange"] = (ev, data) => {
        if (data.value.length <= CONST.TEXTAREA_MAX_NUM_CHARACTERS) {
            setPriorInformation(data.value);
            countPriorInformationRef.current = data.value.length;
        }
    };

    const onChangeOutputMethod: TextareaProps["onChange"] = (ev, data) => {
        if (data.value.length <= CONST.TEXTAREA_MAX_NUM_CHARACTERS) {
            setOutputMethod(data.value);
            countOutputMethodRef.current = data.value.length;
        }
    };

    const onChangeEnabled = useCallback(
        (ev: any) => {
            setEnabled(ev.currentTarget.checked);
        },
        [setEnabled]
    );

    const handleSubmit = async (ev: FormEvent) => {
        ev.preventDefault();
        const result = await callApiCustomInstructionUpdate();
        if (result && result.status === 200) {
            const settings = {
                prior_information: priorInformation,
                output_method: outputMethod,
                enabled: enabled
            };
            sessionStorage.setItem("custom_instruction", JSON.stringify(settings));
            setIsOpen(false);
        }
    };

    const callApiCustomInstructionGet = async () => {
        try {
            const request: CustomInstructionGetRequest = {
                userId: userId
            };
            const result = await customInstructionGetApi(request);

            const customInstruction: CustomInstructionGetResponse = {
                status: result.status,
                error: result.error ?? "",
                priorInformation: result.priorInformation,
                outputMethod: result.outputMethod,
                enabled: result.enabled
            };
            return customInstruction;
        } catch (e) {
            console.debug("Error occurs.");
        } finally {
            //NOP
        }
    };

    const callApiCustomInstructionUpdate = async () => {
        try {
            const request: CustomInstructionUpdateRequest = {
                userId: userId,
                priorInformation: priorInformation,
                outputMethod: outputMethod,
                enabled: enabled
            };
            const result = await customInstructionUpdateApi(request);

            const answerEvaluate: CustomInstructionUpdateResponse = {
                status: result.status,
                error: result.error ?? ""
            };
            return answerEvaluate;
        } catch (e) {
            alert("更新できませんでした。");
        } finally {
            //NOP
        }
    };

    const PopoverPriorInformation = () => {
        return (
            <div className={styles.popover}>
                <h3 className={styles.contentHeader}>例</h3>
                <ul>
                    <li>どこに住んでいますか？</li>
                    <li>仕事は何ですか？</li>
                    <li>趣味や興味があることは何ですか？</li>
                    <li>関心のある話題は何ですか？</li>
                    <li>あなたの目標は何ですか？</li>
                </ul>
            </div>
        );
    };

    const PopoverOutputMethod = () => {
        return (
            <div className={styles.popover}>
                <h3 className={styles.contentHeader}>例</h3>
                <ul>
                    <li>回答のフォーマル度合いやカジュアル度合は？</li>
                    <li>回答はどれくらいの長さ、短さが適切ですか？</li>
                    <li>あなたをどうお呼びしますか？</li>
                    <li>PowerGenAIはトピックについて意見を持つべきですか、それとも中立であるべきですか？</li>
                </ul>
            </div>
        );
    };

    const [isOpenPriorInformationPopover, setIsOpenPriorInformationPopover] = useState(false);
    const handleOpenChangePriorInformation: PopoverProps["onOpenChange"] = (e, data) => setIsOpenPriorInformationPopover(data.open || false);

    const [isOpenOutputMethodPopover, setisOpenOutputMethodPopover] = useState(false);
    const handleOpenChangeOutputMethod: PopoverProps["onOpenChange"] = (e, data) => setisOpenOutputMethodPopover(data.open || false);

    return (
        <div className={styles.dialogWrapper}>
            <FluentProvider className={styles.provider} theme={webLightTheme}>
                <DialogSurface className={styles.dialogSurface}>
                    <form onSubmit={handleSubmit}>
                        <DialogBody className={styles.dialogBody}>
                            <div className={styles.dialogTitle}>
                                <DialogTitle>{CONST.MENU.CUSTOM_INSTRUCTIONS}</DialogTitle>
                            </div>
                            <DialogContent className={styles.dialogContents}>
                                <div className={styles.dialogContent}>
                                    <Field label="より良い回答を得るために、あなたについて教えてください。">
                                        <Popover
                                            open={isOpenPriorInformationPopover}
                                            onOpenChange={handleOpenChangePriorInformation}
                                            trapFocus={true}
                                            positioning={{ offset: 10, position: "after", align: "top" }}
                                        >
                                            <PopoverTrigger>
                                                <span>{/* この要素がPopover表示のルートになる */}</span>
                                            </PopoverTrigger>
                                            <PopoverSurface className={styles.popoverSurface}>
                                                <PopoverPriorInformation />
                                            </PopoverSurface>
                                        </Popover>
                                        <Textarea
                                            appearance="outline"
                                            value={priorInformation}
                                            onChange={onChangePriorInformation}
                                            onClick={(e: any) => setIsOpenPriorInformationPopover(true)}
                                            className={styles.textArea}
                                        />
                                        <Text className={styles.counter}>
                                            {countPriorInformationRef.current}/{CONST.TEXTAREA_MAX_NUM_CHARACTERS}
                                        </Text>
                                    </Field>
                                </div>
                                <div className={styles.dialogContent}>
                                    <Field label="どのような回答を希望しますか？">
                                        <Popover
                                            open={isOpenOutputMethodPopover}
                                            onOpenChange={handleOpenChangeOutputMethod}
                                            trapFocus={true}
                                            size="small"
                                            positioning={{ offset: 10, position: "after", align: "top" }}
                                        >
                                            <PopoverTrigger>
                                                <span>{/* この要素がPopover表示のルートになる */}</span>
                                            </PopoverTrigger>
                                            <PopoverSurface className={styles.popoverSurface}>
                                                <PopoverOutputMethod />
                                            </PopoverSurface>
                                        </Popover>
                                        <Textarea
                                            appearance="outline"
                                            value={outputMethod}
                                            onChange={onChangeOutputMethod}
                                            onClick={(e: any) => setisOpenOutputMethodPopover(true)}
                                            className={styles.textArea}
                                        />
                                        <Text className={styles.counter}>
                                            {countOutputMethodRef.current}/{CONST.TEXTAREA_MAX_NUM_CHARACTERS}
                                        </Text>
                                    </Field>
                                </div>
                                <Switch
                                    checked={enabled}
                                    onChange={onChangeEnabled}
                                    label="新しいチャットに適用する"
                                    labelPosition="before"
                                    {...(isSwitchDisabled ? { disabled: true } : {})}
                                    className={styles.dialogSwitch}
                                    input={{ as: "input" }}
                                />
                            </DialogContent>
                            <DialogActions className={styles.dialogActions}>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="secondary">Cancel</Button>
                                </DialogTrigger>
                                <Button type="submit" appearance="primary" disabled={!hasChanged}>
                                    Save
                                </Button>
                            </DialogActions>
                        </DialogBody>
                    </form>
                </DialogSurface>
            </FluentProvider>
        </div>
    );
};
