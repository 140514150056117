import * as CONST from "./constant";

export class GptVersions {
    static readonly GPT_3_5 = CONST.AI_MODEL_NAME.GPT_3_5;
    static readonly GPT_4 = CONST.AI_MODEL_NAME.GPT_4;

    static convertToDisplayString(version: string): string {
        switch (version) {
            case CONST.AI_MODEL_VALUE.GPT_3_5:
                return GptVersions.GPT_3_5;
            case CONST.AI_MODEL_VALUE.GPT_4:
                return GptVersions.GPT_4;
            default:
                return version;
        }
    }
}
