import { PencilSquare } from "react-bootstrap-icons";

import styles from "./ThreadTitle.module.css";

interface Props {
    threadId: string;
    threadTitle: string;
}

export const ThreadTitle = ({ threadId, threadTitle }: Props) => {
    return (
        <div className={styles.threadTitle}>
            <div className={styles.upperRow}>
                <h1>{threadTitle}</h1>
                {/* <PencilSquare className={styles.icon} /> */}
            </div>
            <span>スレッドID：{threadId}</span>
        </div>
    );
};
