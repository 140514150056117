import React from 'react';
import styles from './ErrorModal.module.css'; 

interface Props {
    onClose: () => void;
}

export const ErrorModal: React.FC<Props> = ({ onClose }) => {
  return (
    <div className={styles.modal}>
      <div className={styles['modal-content']}>
        <p className={styles.errorMessage}>ファイルのアップロードに失敗しました</p>
        <button onClick={onClose} className={`${styles.closeButton} ${styles.blackBackground}`}>閉じる</button>
      </div>
    </div>
  );
};

