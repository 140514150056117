import { useRef, useState, useEffect, useCallback } from "react";
import { Button, Image } from "@fluentui/react-components";
import { ChevronCompactDown, ChevronCompactUp } from "react-bootstrap-icons";
import { useMsal } from "@azure/msal-react";
import { v4 as uuidv4 } from "uuid";

import {
    AskResponse,
    ChatRequest,
    ThreadListRequest,
    threadListApi,
    ThreadListResponse,
    ThreadUpdateRequest,
    threadUpdateApi,
    ThreadUpdateResponse,
    ThreadRemoveRequest,
    threadRemoveApi,
    ThreadRemoveResponse,
    threadDetailApi,
    ThreadDetailResponse,
    ThreadDetailRequest,
    ThreadDeleteRequest,
    ThreadDeleteResponse,
    threadDeleteApi
} from "../../api";

import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { UserChatMessage } from "../../components/UserChatMessage";
import { NewChatButton } from "../../components/NewChatButton";
import { MenuButton } from "../../components/MenuButton";
import { Thread } from "../../components/Thread";
import { ReleaseNote } from "../../components/ReleaseNote/ReleaseNote";
import { ToggleModelButton } from "../../components/ToggleModelButton/ToggleModelButton";
import { SearchTypeToggle } from "../../components/SearchTypeToggle/SearchTypeToggle";
import { LeftPaneToggleButton } from "../../components/LeftPaneButton/LeftPaneToggleButton";
import { DataTypeToggle } from "../../components/DataTypeToggle/DataTypeToggle";
import { TemporaryFeature11 } from "../../components/TemporaryFeature11";
import { ThreadTitle } from "../../components/ThreadTitle/ThreadTitle";
import { InformationPane } from "../../components/Information";
import { HelpPane } from "../../components/HelpPane";
import { PluginSelector } from "../../components/PluginSelector";
import * as CONST from "../../utils/constant";

import styles from "./Chat.module.css";
import headerLogo from "../../assets/ap_logo.png";
import footerLogo from "../../assets/pgai_logo.svg";
import React from "react";

interface ChatProps {
    environment: any;
}

const Chat = ({ environment }: ChatProps) => {
    const timeout = environment.timeout ?? 60000;
    //【TODO】URLが取得できない場合のエラー処理 - エラー画面へ遷移するのが良さげ

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isThreadLoading, setIsThreadLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();
    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: AskResponse][]>([]);
    const [threadId, setThreadId] = useState<string>("");
    const [threads, setThreads] = useState<{ id: string; title: string; updated_at: string }[]>([]);
    const [threadLength, setThreadLength] = useState<number>(0);
    const [selectedThreadId, setSelectedThreadId] = useState<string>("");
    const [selectedThreadTitle, setSelectedThreadTitle] = useState<string>("");
    const [isNewThread, setIsNewThread] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const [isInfoOpen, setIsInfoOpen] = useState(false);
    const [isHelpOpen, setIsHelpOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isChatCompletion, setIsChatCompletion] = useState<boolean>(false);

    const lastQuestionRef = useRef<string>("");
    const lastQuestionFileNameWithTimestampMapRef = useRef<Map<string, string>>(new Map());
    const lastQuestionErrorRef = useRef<string>("");
    const searchTypeRef = useRef<string>("");
    const dataTypeRef = useRef<string>("");
    const aiModelRef = useRef<string>("");
    const pluginIdRef = useRef<string>("");
    const dataIdRef = useRef<string>("");
    const methodIdRef = useRef<string>("");
    const chatMessageStreamTop = useRef<HTMLDivElement | null>(null);
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const { accounts } = useMsal();
    const accountId = (accounts && accounts[0] && accounts[0].localAccountId) ?? "";
    const userId = useRef<string>(accountId);

    useEffect(() => {
        initThreadList();
        setThreadId(threadId => createNewThreadId());
        setSelectedThreadTitle(CONST.DEFAULT_THREAD_TITLE);
        /* ////////【TODO】ローカルストレージに関する暫定対応 - ここから _/_/_/_/_/_/_/_/ */
        for (const key in localStorage) {
            if (localStorage.hasOwnProperty(key)) {
                localStorage.removeItem(key);
            }
        }
        /* ////////【TODO】ローカルストレージに関する暫定対応 - ここまで _/_/_/_/_/_/_/_/ */
        aiModelRef.current = CONST.AI_MODEL_VALUE.GPT_3_5;
        searchTypeRef.current = environment.temporaryFeature1 ? "product" : "regular";
        dataTypeRef.current = environment.temporaryFeature2 ? "1" : "0";
        if (environment.temporaryFeature11) {
            //dataIdRef.current = "1";
            methodIdRef.current = "1";
            searchTypeRef.current = "product";
        }

        // ウィンドウサイズの変更
        if (window.innerWidth < 768) {
            setIsMenuOpen(false);
            setIsMobile(true);
        } else {
            setIsMenuOpen(true);
            setIsMobile(false);
        }

        const handleResize = () => {
            if (window.innerWidth < 768) {
                setIsMenuOpen(false);
                setIsMobile(true);
            } else {
                setIsMenuOpen(true);
                setIsMobile(false);
            }
        };

        window.addEventListener("resize", handleResize);

        // スレッド一覧のスクロール
        const scrollContainer = scrollContainerRef.current;
        scrollContainer?.addEventListener("scroll", handleScroll);

        // スレッド詳細のスクロール
        const chatContentContainer = chatContentRef.current;
        chatContentContainer?.addEventListener("scroll", handleScrollChatContent);

        return () => {
            window.removeEventListener("resize", handleResize);
            scrollContainer?.removeEventListener("scroll", handleScroll);
            chatContentContainer?.removeEventListener("scroll", handleScrollChatContent);
        };
    }, []);

    useEffect(() => {
        if (accountId !== userId.current && accountId !== "") {
            userId.current = accountId;
            initThreadList();
        }
    }, [accountId]);

    const [tokenCount, setTokenCount] = useState<number>(0);

    useEffect(() => {
        if (tokenCount % 2 !== 0) return;
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
    }, [tokenCount]);

    useEffect(() => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
    }, [isLoading, isThreadLoading]);

    useEffect(() => {
        threadListOffsetRef.current = threads.length;
        setThreadLength(threadListOffsetRef.current);
    }, [threads]);

    useEffect(() => {
        if (isMenuOpen || isInfoOpen || isHelpOpen) {
            setIsModalOpen(true);
        } else {
            setIsModalOpen(false);
        }
    }, [isMenuOpen, isInfoOpen, isHelpOpen]);

    const lastAnswerIndexRef = useRef<number>(-1);

    /**
     * チャットスレッドの上下スクロール
     */
    const chatContentRef = useRef<HTMLDivElement>(null);
    const [isChatScrollTop, setIsChatScrollTop] = useState(false);
    const [isChatScrollBottom, setIsChatScrollBottom] = useState(true);

    const handleScrollChatContent = async () => {
        const scrollContainer = chatContentRef.current;
        const currentScrollPos = scrollContainer?.scrollTop ?? 0;
        const scrollHeight = scrollContainer?.scrollHeight ?? 0;
        const offsetHeight = scrollContainer?.offsetHeight ?? 0;
        const chatContainerHeight = scrollHeight - offsetHeight;

        if (currentScrollPos > 0) {
            setIsChatScrollTop(true);
        } else {
            setIsChatScrollTop(false);
        }

        if (currentScrollPos >= chatContainerHeight - 10) {
            setIsChatScrollBottom(false);
        } else {
            setIsChatScrollBottom(true);
        }
    };

    const handleScrollToTop = useCallback(() => {
        chatMessageStreamTop!.current!.scrollIntoView({
            behavior: "smooth",
            block: "end"
        });
    }, [chatMessageStreamTop]);

    const handleScrollToBottom = useCallback(() => {
        chatMessageStreamEnd!.current!.scrollIntoView({
            behavior: "smooth"
        });
    }, [chatMessageStreamEnd]);

    const log_debug = (datetime: string, message: string) => {
        if (environment.consoleLogOutput) console.debug(datetime, message);
    };

    const makeApiRequest = async (question: string, newFileNamesMap: Map<any, any>) => {
        // 子コンポネントからタイムスタンプ付きのファイル名付きMapを保持しておく
        lastQuestionFileNameWithTimestampMapRef.current = newFileNamesMap;

        const filenames = Array.from(newFileNamesMap.keys()).map(fileName => "・" + fileName);
        let lastQuestionFileName = filenames.length ? filenames.join('\n') : '';
        lastQuestionRef.current = lastQuestionFileName ? `${lastQuestionFileName}\n${question}` : question;

        error && setError(undefined);
        lastQuestionErrorRef.current = question

        setIsChatCompletion(false);
        setIsLoading(true);
        setTokenCount(0);

        const waitForDisplay = async (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

        try {
            let hasToken = false;

            const startDatetime = new Date().toISOString();
            log_debug(startDatetime, " - Process START.");

            lastAnswerIndexRef.current = answers.length - 1;

            // 添付ファイル名のリストを作成
            const files: string[] = [];
            if ('gpt-4' === aiModelRef.current) {
                lastQuestionFileNameWithTimestampMapRef.current.forEach((value: string) => {
                    files.push(value);
                });
            }

            // モデルと検索種別を設定する
            const request: ChatRequest = {
                question: question,
                id: threadId,
                user_id: accountId,
                model: aiModelRef.current,
                type: searchTypeRef.current,
                dataId: dataIdRef.current,
                methodId: methodIdRef.current,
                pluginId: pluginIdRef.current,
                files: files,
                plugins: selectedPlugins,
            };

            const generator = streamChatCompletion(request, timeout);
            if (!generator) {
                // ここに入ること自体ないようだが、一応
                throw new Error("Request failed");
            }

            let result = "";
            for await (let token of generator) {
                if (result === "") {
                    log_debug(new Date().toISOString(), " - Response START.");
                }

                if (token.startsWith("assistant:")) {
                    token = token.replace("assistant:", "");
                }
                log_debug(new Date().toISOString(), token);
                result += token;

                const answer: AskResponse = {
                    answer: result,
                    data_points: [],
                    thoughts: "",
                    model: aiModelRef.current,
                    type: searchTypeRef.current,
                    evaluate: 0
                };
                setAnswers([...answers, [lastQuestionFileName ? `${lastQuestionFileName}\n${question}` : question, answer]]);
                await waitForDisplay(50);
                setTokenCount(tokenCount => tokenCount + 1);

                hasToken = true;
            }

            log_debug(new Date().toISOString(), " - Response END.");

            if (hasToken === false) {
                // generator でエラーが補足できないので、トークンの有無で判定している
                throw new Error("The rate limit has been reached.\nPlease wait a moment and try again.");
            }

            if (result.length === 0) {
                throw new Error("Error occurs.\nPlease wait a moment and try again.");
            }

            setTokenCount(tokenCount => tokenCount + 1);

            const endDatetime = new Date().toISOString();
            const postAnswer: AskResponse = {
                answer: result,
                start_time: startDatetime,
                end_time: endDatetime,
                data_points: [],
                thoughts: "",
                model: aiModelRef.current,
                type: searchTypeRef.current,
                evaluate: 0
            };
            const threadTitleQuestion = question; //スレッドタイトル用
            setAnswers([...answers, [lastQuestionFileName ? `${lastQuestionFileName}\n${question}` : question, postAnswer]]);
            setIsChatCompletion(isChatCompletion => true);
            await waitForDisplay(100);

            // スレッド一覧の表示を設定する
            if (isNewThread) {
                const newThreadTitle = threadTitleQuestion.slice(0, CONST.THREAD_TITLE_MAX_NUM_CHARACTERS);
                setThreads(threads => [{ id: threadId, title: newThreadTitle, updated_at: new Date().toISOString() }, ...threads]);
                setSelectedThreadTitle(newThreadTitle);
                setIsNewThread(false);
            } else {
                const updatedThreads = threads.map(thread => {
                    if (thread.id === threadId) {
                        thread.updated_at = new Date().toISOString();
                    }
                    return thread;
                });
                updatedThreads.sort((a, b) => {
                    if (a.updated_at < b.updated_at) return 1;
                    if (a.updated_at > b.updated_at) return -1;
                    return 0;
                });
                setThreads(updatedThreads);
            }
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
            setIsChatCompletion(false);
            setTokenCount(0);
            log_debug(new Date().toISOString(), " - Process END.");
        }
    };

    /**
     * ジェネレーター関数
     * @param request
     */
    async function* streamChatCompletion(request: ChatRequest, timeout: number) {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => {
            controller.abort();
        }, timeout);

        try {
            const response = await fetch("/chat", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    question: request.question,
                    id: request.id,
                    user_id: request.user_id,
                    model: request.model,
                    type: request.type,
                    dataId: request.dataId,
                    methodId: request.methodId,
                    pluginId: request.pluginId,
                    files: request.files,
                    plugins: request.plugins
                }),
                signal: controller.signal
            });

            clearTimeout(timeoutId);

            const reader = response?.body?.getReader();

            if (response.status !== 200 || !reader) {
                const msg = response.statusText || "Request failed";
                throw new Error(msg);
            }

            const decoder = new TextDecoder("utf-8");

            let done = false;
            while (!done) {
                const { done: readDone, value } = await reader.read();
                if (readDone) {
                    done = readDone;
                    reader.releaseLock();
                } else {
                    const token = decoder.decode(value, { stream: true });
                    yield token;
                }
            }
        } catch (error: any) {
            if (error.name === "AbortError") {
                console.debug("Request timed out");
                throw new Error("Request timed out");
            } else {
                const msg = error?.message || error?.name || "Request error occurred";
                console.debug(msg);
                throw new Error(msg);
            }
        } finally {
            clearTimeout(timeoutId);
        }
    }

    /**
     * 新規チャット
     */
    const newThread = () => {
        if (isNewThread) return false;

        lastQuestionRef.current = "";
        searchTypeRef.current = environment.temporaryFeature1 ? "product" : "regular";
        dataTypeRef.current = environment.temporaryFeature2 ? "1" : "0";
        if (environment.temporaryFeature11) {
            dataIdRef.current = "1";
            methodIdRef.current = "1";
            searchTypeRef.current = "product";
        }
        error && setError(undefined);
        setAnswers([]);
        setThreadId(createNewThreadId());
        setIsNewThread(true);
        setSelectedThreadId("");
        setSelectedThreadTitle(CONST.DEFAULT_THREAD_TITLE);

        if (isMobile) {
            setIsMenuOpen(false);
        }
    };

    const initThreadList = async () => {
        const result = await callApiThreadList(threadListOffsetRef.current, CONST.THREAD_LIST.INITIAL_NUM);
        const threadList = result?.list ?? [];
        setThreads(threadList);
    };

    const updateThreadList = async () => {
        console.log("updateThreadList");
    };

    const selectThread = async (threadId: string, threadTitle: string) => {
        setIsThreadLoading(true);
        lastQuestionRef.current = "スレッド履歴から";
        setAnswers([]);
        setSelectedThreadId(threadId);
        setSelectedThreadTitle(threadTitle);

        // _/_/_/_/_/_/_/_/ APIで取得した値からの復元 - ここから _/_/_/_/_/_/_/_/
        const r1 = await callApiThreadDetail(threadId);
        const r2 = r1 && r1.thread ? r1.thread : [];
        const r3 = r2.map(t => {
            const answer: AskResponse = {
                answer: t.answer,
                start_time: t.start_time,
                end_time: t.end_time,
                data_points: [],
                thoughts: "",
                model: t.model ?? "",
                type: t.type ?? "",
                evaluate: t.evaluate ?? 0
            };
            return [t.files.length ? `${t.files}\n${t.user_input}` : t.user_input, answer] as [user: string, response: AskResponse];
        });
        setAnswers([...r3]);
        // _/_/_/_/_/_/_/_/ APIで取得した値からの復元 - ここまで _/_/_/_/_/_/_/_/

        setIsNewThread(false);
        setThreadId(threadId);
        setIsThreadLoading(false);

        if (isMobile) {
            setIsMenuOpen(false);
        }

        lastAnswerIndexRef.current = r3.length - 1;
    };

    const updateThread = async (threadId: string, title: string) => {
        await callApiThreadUpdate(threadId, title);

        const updatedThreads = threads.map((thread, index) => {
            if (thread.id === threadId) {
                threads[index].title = title;
                threads[index].updated_at = new Date().toISOString();
            }
            return thread;
        });

        const reversedThreads = updatedThreads.sort((a, b) => {
            if (a.updated_at < b.updated_at) return 1;
            if (a.updated_at > b.updated_at) return -1;
            return 0;
        });

        setThreads([...reversedThreads]);
        setSelectedThreadTitle(title);
        await new Promise(resolve => setTimeout(resolve, 100));
    };

    const removeThread = async (threadId: string) => {
        await callApiThreadRemove(threadId);

        const filteredThreads = threads.filter(thread => thread.id !== threadId);

        setThreads([...filteredThreads]);
        await new Promise(resolve => setTimeout(resolve, 100));

        newThread();

        if (threads.length < 40) {
            await fetchThreadList(CONST.THREAD_LIST.INITIAL_NUM - threads.length);
        }
    };

    const deleteThread = async () => {
        await callApiThreadDelete(accountId);
        setThreads([]);
        newThread();
    };

    const createNewThreadId = () => {
        return uuidv4();
    };

    /**
     * スレッド一覧の下スクロールで、続きを読み込む
     */
    const [isLoadingThreadList, setIsLoadingThreadList] = useState<boolean>(false);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const throttleTimeoutRef = useRef(false);
    const prevScrollPosRef = useRef(0);
    const threadListOffsetRef = useRef(0);

    const handleScroll = async () => {
        const scrollContainer = scrollContainerRef.current;
        const currentScrollPos = scrollContainer?.scrollTop ?? 0;

        if (currentScrollPos > prevScrollPosRef.current) {
            if (isScrolledToEnd(scrollContainer) && !throttleTimeoutRef.current && !isLoadingThreadList) {
                throttleTimeoutRef.current = true;
                setTimeout(() => {
                    throttleTimeoutRef.current = false;
                    fetchThreadList(CONST.THREAD_LIST.LIMIT);
                }, 500);
            }
        }

        prevScrollPosRef.current = currentScrollPos;
    };

    const isScrolledToEnd = (element: HTMLDivElement | null) => {
        if (!element) return false;
        return element.scrollTop + element.clientHeight + 10 >= element.scrollHeight;
    };

    const fetchThreadList = async (threadListLimit: number) => {
        try {
            setIsLoadingThreadList(true);
            const result = await callApiThreadList(threadListOffsetRef.current, threadListLimit);
            const threadList = result?.list ?? [];
            if (threadList.length > 0) {
                setThreads(threads => [...threads, ...threadList]);
            }
        } catch (error) {
            console.log("error[" + error + "]");
        } finally {
            setIsLoadingThreadList(false);
        }
    };

    const callApiThreadList = async (offset: number, limit: number) => {
        error && setError(undefined);

        try {
            const request: ThreadListRequest = {
                user_id: userId.current,
                offset: offset,
                limit: limit
            };
            const result = await threadListApi(request);
            const threadList: ThreadListResponse = {
                status: result.status,
                list: result.list ?? []
            };
            //threadListをreturnする
            return threadList;
        } catch (e) {
            setError(e);
        } finally {
            //NOP
        }
    };

    const callApiThreadDetail = async (threadId: string) => {
        error && setError(undefined);

        try {
            const request: ThreadDetailRequest = {
                user_id: accountId,
                id: threadId
            };
            const result = await threadDetailApi(request);
            const threadDetail: ThreadDetailResponse = {
                status: result.status,
                thread: result.thread,
                error: result.error ?? ""
            };
            threadDetail.thread.forEach(thread => {
                if (thread.files.length) {
                    // ファイル名の末尾のタイムスタンプ文字列をトリムしてファイル名復元
                    const filesWithPrefix = thread.files.map(fileName => `・${fileName.replace(/_\d{14}\./, '.')}`);
                    thread.files = [filesWithPrefix.join('\n')];
                }
            });
            return threadDetail;
        } catch (e) {
            setError(e);
        } finally {
            //NOP
        }
    };

    const callApiThreadUpdate = async (threadId: string, title: string) => {
        error && setError(undefined);

        try {
            const request: ThreadUpdateRequest = {
                user_id: accountId,
                id: threadId,
                title: title
            };
            const result = await threadUpdateApi(request);
            const threadList: ThreadUpdateResponse = {
                status: result.status,
                error: result.error ?? ""
            };
        } catch (e) {
            setError(e);
        } finally {
            //NOP
        }
    };

    const callApiThreadRemove = async (threadId: string) => {
        error && setError(undefined);

        try {
            const request: ThreadRemoveRequest = {
                user_id: accountId,
                id: threadId
            };
            const result = await threadRemoveApi(request);
            const threadList: ThreadRemoveResponse = {
                status: result.status,
                error: result.error ?? ""
            };
        } catch (e) {
            setError(e);
        } finally {
            //NOP
        }
    };

    const callApiThreadDelete = async (userId: string) => {
        error && setError(undefined);

        try {
            const request: ThreadDeleteRequest = {
                user_id: userId
            };
            const result = await threadDeleteApi(request);
            const threadList: ThreadDeleteResponse = {
                status: result.status,
                error: result.error ?? ""
            };
        } catch (e) {
            setError(e);
        } finally {
            //NOP
        }
    };

    const handleClickLeftPaneClose = () => {
        setIsMenuOpen(false);
    };

    const handleClickLeftPaneOpen = () => {
        setIsMenuOpen(true);
    };

    const handleClickLeftPaneToggle = () => {
        if (isMobile) {
            setIsInfoOpen(false);
            setIsHelpOpen(false);
        }
        setIsMenuOpen(!isMenuOpen);
    };

    const handleClickInfoPaneToggle = () => {
        if (isMobile) setIsMenuOpen(false);
        setIsHelpOpen(false);
        setIsInfoOpen(!isInfoOpen);
    };

    const handleClickHelpPaneToggle = () => {
        if (isMobile) setIsMenuOpen(false);
        setIsInfoOpen(false);
        setIsHelpOpen(!isHelpOpen);
    };

    const handleClickMenuOpenModal = () => {
        setIsMenuOpen(false);
        setIsInfoOpen(false);
        setIsHelpOpen(false);
    };

    const [aiModel, setAiModel] = useState(CONST.AI_MODEL_VALUE.GPT_3_5);
    const handleModelChange = useCallback((model: string) => {
        setAiModel(model);
        aiModelRef.current = model;
    }, []);

    const [selectedPlugins, setSelectedPlugins] = useState<string[]>([]);
    const handlePluginChange = (plugins: string[]) => {
        setSelectedPlugins(plugins);
    };
    const [fileNamesMap, setFileNamesMap] = React.useState(new Map());
    // ChildComponent から fileNamesMap を受け取り、親コンポーネントで設定する関数
    const handleFileNamesMapChange = (newFileNamesMap: React.SetStateAction<Map<any, any>>) => {
        setFileNamesMap(newFileNamesMap);
    };

    return (
        <div className={styles.container}>
            <header className={styles.siteHeader}>
                <h2>
                    {environment.logoUrl ? (
                        <div className={styles.productLogo}>
                            <img src={environment.logoUrl} alt="PowerGenAI" title="PowerGenAI"></img>
                        </div>
                    ) : (
                        <Image role="presentation" className={styles.headerLogo} src={headerLogo} />
                    )}
                </h2>
                <MenuButton
                    environment={environment}
                    onClearConversationsClicked={deleteThread}
                    onClickInfo={handleClickInfoPaneToggle}
                    onClickHelp={handleClickHelpPaneToggle}
                    threadLength={threadLength}
                />
            </header>
            <div className={styles.pageWrap}>
                <div className={`${styles.sideMenu} ${isMenuOpen ? "" : styles.leftPaneHidden}`}>
                    <div className={styles.historyContainer}>
                        <div className={styles.newChat}>
                            <NewChatButton onClick={newThread} disabled={!lastQuestionRef.current || isLoading} />
                        </div>
                        <div ref={scrollContainerRef} className={styles.historyList}>
                            <div id="threadList">
                                <ol>
                                    {threads.map((thread, index) => (
                                        <Thread
                                            key={thread.id}
                                            threadId={thread.id}
                                            title={thread.title}
                                            selectedThreadId={selectedThreadId}
                                            onThreadClicked={selectThread}
                                            onRemoveThreadClicked={removeThread}
                                            onThreadTitleChanged={updateThread}
                                        />
                                    ))}
                                </ol>
                            </div>
                        </div>
                        <div className={styles.sideMenuFooter}>
                            <Image role="presentation" className={styles.footerLogo} src={footerLogo} />
                        </div>
                        <LeftPaneToggleButton isOpen={isMenuOpen} onClick={handleClickLeftPaneToggle} className={styles.historyHandle} />
                    </div>
                </div>
                <div className={`${styles.chatRoot} ${!isMenuOpen ? styles.leftPaneOpen : ""}`}>
                    <div className={styles.chatContainer}>
                        {isMobile && isModalOpen && (
                            <>
                                <div className={styles.mobileMenuOpenModal} onClick={handleClickMenuOpenModal}></div>
                            </>
                        )}
                        <div className={styles.subHeader}>
                            <div className={styles.subHeaderContainer}>
                                <ThreadTitle threadId={threadId} threadTitle={selectedThreadTitle} />
                                <div className={styles.subHeaderButtons}>
                                    {environment.temporaryFeature1 && (
                                        <>
                                            <SearchTypeToggle environment={environment} searchTypeRef={searchTypeRef} />
                                        </>
                                    )}
                                    {environment.temporaryFeature2 && (
                                        <>
                                            <DataTypeToggle environment={environment} dataTypeRef={dataTypeRef} />
                                        </>
                                    )}
                                    {environment.temporaryFeature11 && (
                                        <>
                                            <TemporaryFeature11
                                                environment={environment}
                                                dataIdRef={dataIdRef}
                                                methodIdRef={methodIdRef}
                                                searchTypeRef={searchTypeRef}
                                            />
                                        </>
                                    )}
                                    <PluginSelector onChange={handlePluginChange} />
                                    <div style={{ marginBottom: '5px' }} />
                                    <ToggleModelButton environment={environment} aiModelRef={aiModelRef} onModelChange={handleModelChange} />
                                </div>
                            </div>
                        </div>

                        <div className={styles.chatContent} ref={chatContentRef}>
                            {!lastQuestionRef.current ? (
                                <div className={styles.chatEmptyState}></div>
                            ) : (
                                <>
                                    <div className={styles.chatMessageStream}>
                                        <div ref={chatMessageStreamTop} className={"chatMessageStreamTop"} />
                                        {answers.map((answer, index) => (
                                            <div key={index} className={styles.chatTurn}>
                                                <UserChatMessage message={answer[0]} />
                                                <div className={styles.roleAssistant}>
                                                    <Answer
                                                        key={index}
                                                        threadId={threadId}
                                                        index={index}
                                                        answer={answer[1].answer}
                                                        isSelected={selectedAnswer === index}
                                                        isLoading={isLoading}
                                                        inProgress={isLoading && lastAnswerIndexRef.current < index}
                                                        environment={environment}
                                                        modelName={answer[1].model ?? ""}
                                                        evaluate={answer[1].evaluate ?? 0}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        {isLoading && tokenCount === 0 && (
                                            <>
                                                <UserChatMessage message={lastQuestionRef.current} />
                                                <div className={styles.roleAssistant}>
                                                    <AnswerLoading />
                                                </div>
                                            </>
                                        )}
                                        {error ? (
                                            <>
                                                <UserChatMessage message={lastQuestionRef.current} />
                                                <div className={styles.roleAssistant}>
                                                    <AnswerError error={error.toString()}
                                                        onRetry={() => makeApiRequest(lastQuestionErrorRef.current, lastQuestionFileNameWithTimestampMapRef.current)} />
                                                </div>
                                            </>
                                        ) : null}
                                        <div ref={chatMessageStreamEnd} className={"chatMessageStreamEnd"} />
                                    </div>
                                    {isChatScrollTop && (
                                        <div className={styles.topBtn}>
                                            <Button onClick={handleScrollToTop}>
                                                <ChevronCompactUp />
                                            </Button>
                                        </div>
                                    )}
                                    {isChatScrollBottom && (
                                        <div className={styles.bottomBtn}>
                                            <Button onClick={handleScrollToBottom}>
                                                <ChevronCompactDown />
                                            </Button>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        {/* .chatContent ここまで */}

                        {/* 入力部 - ここから */}
                        <div className={styles.chatInput}>
                            <QuestionInput
                                environment={environment}
                                clearOnSend
                                placeholder="メッセージを送信"
                                isLoading={isLoading}
                                onSend={question => makeApiRequest(question, fileNamesMap)}
                                threadId={threadId}
                                showAttachmentIcon={aiModel === CONST.AI_MODEL_VALUE.GPT_3_5}
                                usId={userId.current}
                                onFileNamesMapChange={handleFileNamesMapChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* _/_/_/_/_/_/_/_/ .pageWrap - ここまで _/_/_/_/_/_/_/_/ */}
            {environment.useInformation && (
                <>
                    <InformationPane isOpen={isInfoOpen} onClick={handleClickInfoPaneToggle} />
                </>
            )}
            {environment.useHelp && (
                <>
                    <HelpPane isOpen={isHelpOpen} onClick={handleClickHelpPaneToggle} />
                </>
            )}
        </div>
    );
};

export default Chat;
