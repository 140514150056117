import { ChevronDoubleLeft16Regular, ChevronDoubleRight16Regular } from "@fluentui/react-icons";

interface Props {
    isOpen: boolean;
    onClick: () => void;
    className?: string;
}

export const LeftPaneToggleButton = ({ isOpen, onClick, className }: Props) => {
    return (
        <div>
            <button className={className} onClick={onClick} type="button" title="">
                <span>{isOpen ? <ChevronDoubleLeft16Regular /> : <ChevronDoubleRight16Regular />}</span>
            </button>
        </div>
    );
};
