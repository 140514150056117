import React from "react";
import ReactDOM from "react-dom/client";
import { initializeIcons } from "@fluentui/react";
import { InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { callMsGraph, getEnvironmentApi, personalSettingsGetApi } from "./api";
import { getMsalConfig } from "./auth/authUtil";
import { updateLoginHistory } from "./services/LoginHistoryService";
import App from "./App";

import "./index.css";
import "github-markdown-css/github-markdown-dark.css";
import "highlight.js/styles/github-dark.css";

const environment = await getEnvironmentApi();
const msalConfig = getMsalConfig(environment);
const msalInstance = new PublicClientApplication(msalConfig);

await msalInstance
    .handleRedirectPromise()
    .then(tokenResponse => {
        if (!tokenResponse) {
            const allAccounts = msalInstance.getAllAccounts();
            if (allAccounts.length === 0) {
                msalInstance.loginRedirect();
            }
        } else {
            // Do something with the tokenResponse
        }
    })
    .catch(err => {
        console.log(err);
    });

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    //ログイン後にだけ通るここでログイン履歴を更新する
    const activeAccount = msalInstance.getActiveAccount();
    if (environment.useLoginHistory && activeAccount) {
        let principalName = "";
        if ("Microsoft_Entra_ID" === environment.authType) {
            const accessTokenRequest = {
                scopes: ["User.Read"],
                account: activeAccount
            };

            const userGraph = await msalInstance
                .acquireTokenSilent(accessTokenRequest)
                .then(response => {
                    return callMsGraph(response.accessToken);
                })
                .catch(error => {
                    if (error instanceof InteractionRequiredAuthError) {
                        msalInstance.acquireTokenRedirect(accessTokenRequest);
                    } else {
                        console.debug("Error occurs(1).");
                    }
                });
            principalName = userGraph !== undefined ? userGraph.userPrincipalName : activeAccount.username;
        } else {
            principalName = activeAccount.idTokenClaims?.emails?.[0] || "";
        }
        
        console.debug("MSAL active account user naname: " + activeAccount.username)
        await updateLoginHistory(activeAccount.localAccountId, principalName);
    }
}

/**
 * ログイン後、管理画面の設定からGPT-4の利用可否を取得し設定する
 */
if (environment.useAdminSystem && msalInstance.getActiveAccount()) {
    const activeAccount = msalInstance.getActiveAccount();
    if (activeAccount) {

        let principalName = "";
        if ("Microsoft_Entra_ID" === environment.authType) {
            const accessTokenRequest = {
                scopes: ["User.Read"],
                account: activeAccount
            };

            const userGraph = await msalInstance
                .acquireTokenSilent(accessTokenRequest)
                .then(response => {
                    return callMsGraph(response.accessToken);
                })
                .catch(error => {
                    if (error instanceof InteractionRequiredAuthError) {
                        msalInstance.acquireTokenRedirect(accessTokenRequest);
                    } else {
                        console.debug("Error occurs(2).");
                    }
                });

            principalName = userGraph !== undefined ? userGraph.userPrincipalName : activeAccount.username;
        } else {
            principalName = activeAccount.idTokenClaims?.emails?.[0] || "";
        }

        if (principalName) {
            const GPT4_IS_ENABLE = "GPT-4 is available from the administrator settings.";
            const GPT4_IS_DISABLE = "GPT-4 is not available from the administrator settings.";
            try {
                await personalSettingsGetApi(principalName).then(personalSettings => {
                    if (personalSettings.status === 200) {
                        const setting = personalSettings.settings;
                        environment.useModelGpt4 = setting.model === "1" ? true : false;
                        console.debug(environment.useModelGpt4 ? GPT4_IS_ENABLE : GPT4_IS_DISABLE);
                    } else {
                        environment.useModelGpt4 = false;
                        console.debug(GPT4_IS_DISABLE);
                    }
                });
            } catch (e) {
                environment.useModelGpt4 = false;
                console.debug("Error orrurs when getting personal settings.");
            }
        } else {
            environment.useModelGpt4 = false;
            const MSG_ERR_USERGRAPH = "Error - UserGraph is undefined.";
            console.debug(MSG_ERR_USERGRAPH);
        }
    }
}

initializeIcons();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App instance={msalInstance} environment={environment} />
    </React.StrictMode>
);
