import { useState } from "react";
import { Button } from "@fluentui/react-components";
import { ThumbLike16Regular } from "@fluentui/react-icons";

import styles from "./AnswerButton.module.css";

interface Props {
    checked: boolean;
    onClick: () => Promise<boolean>;
}

export const LikeButton = ({ checked, onClick }: Props) => {
    const [isLikeClicked, setIsLikeClicked] = useState<boolean>(checked);
    const buttonClassName = isLikeClicked ? styles.buttonClicked : styles.button;

    return (
        <>
            <Button
                className={buttonClassName}
                icon={<ThumbLike16Regular />}
                onClick={async () => {
                    const result = await onClick();
                    if (result) {
                        setIsLikeClicked(true);
                    }
                }}
                disabled={isLikeClicked}
            ></Button>
        </>
    );
};
