import { useRef, useState } from "react";
import { Button } from "@fluentui/react-components";
import { Clipboard16Regular, ClipboardCheckmark16Regular } from "@fluentui/react-icons";

import styles from "./AnswerButton.module.css";

interface Props {
    answer: string;
}

export const ClipboardButton = ({ answer }: Props) => {
    const inProgressRef = useRef<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const onClickClipboard = async () => {
        try {
            inProgressRef.current = true;
            setIsDisabled(inProgressRef.current);
            await navigator.clipboard.writeText(answer).then(async () => {
                await new Promise(resolve => setTimeout(resolve, 1000));
            });
        } catch (err) {
            console.error("Failed to copy answer: ", err);
        } finally {
            inProgressRef.current = false;
            setIsDisabled(inProgressRef.current);
        }
    };

    return (
        <>
            {!inProgressRef.current ? (
                <Button
                    className={styles.button}
                    icon={<Clipboard16Regular />}
                    onClick={() => {
                        onClickClipboard();
                    }}
                    disabled={isDisabled}
                ></Button>
            ) : (
                <Button className={styles.button} icon={<ClipboardCheckmark16Regular />}></Button>
            )}
        </>
    );
};
