import React, { useState, useRef, useEffect } from 'react';
import styles from './PluginSelector.module.css';
import { PuzzlePiece24Filled, PuzzlePiece24Regular } from '@fluentui/react-icons';
import { Image } from "@fluentui/react-components";
import binglogo from "../../assets/bing.svg";
interface PluginOption {
  value: string;
  label: string;
}

const pluginOptions: PluginOption[] = [
  { value: 'bing_search', label: 'Bing Search' },
];

interface PluginSettings {
  [key: string]: boolean;
}

interface Props {
  onChange: (selectedPlugins: string[]) => void;
}

export const PluginSelector: React.FC<Props> = ({ onChange }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [pluginSettings, setPluginSettings] = useState<PluginSettings>(
    pluginOptions.reduce((settings: PluginSettings, option: PluginOption) => {
      settings[option.value] = false;
      return settings;
    }, {})
  );
  const wrapperRef = useRef<HTMLDivElement>(null); // 型を追加

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setShowOptions(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCheckboxChange = (optionValue: string) => {
    // チェックボックスの状態を更新
    const updatedSettings = {
      ...pluginSettings,
      [optionValue]: !pluginSettings[optionValue]
    };
    setPluginSettings(updatedSettings);

    // 選択されたプラグインを検索して親コンポーネントに通知
    const selectedPlugins = Object.keys(updatedSettings).filter(key => updatedSettings[key]);
    onChange(selectedPlugins);
  };
  // チェックされた件数をカウント
  const checkedCount = Object.values(pluginSettings).filter(checked => checked).length;

  return (
    <div ref={wrapperRef} className={styles.pluginSelector}>
      <button onClick={() => setShowOptions(!showOptions)} className={styles.button}>
        {checkedCount > 0 ? <PuzzlePiece24Filled className={styles.selectedIcon} /> : <PuzzlePiece24Regular />}
      </button>
      {showOptions && (
        <div className={styles.optionsList}>
          <div className={styles.title}>{checkedCount} 件選択 </div>
          {pluginOptions.map(option => (
            <label key={option.value} className={`${styles.option}`}>
              <span className={styles.icon}>
                <Image role="presentation" className={styles.icon} src={binglogo} />
              </span>
              <span className={styles.text}>{option.label}</span>
              <input
                type="checkbox"
                name={option.value}
                checked={pluginSettings[option.value]}
                onChange={() => handleCheckboxChange(option.value)}
              />
            </label>
          ))}
        </div>
      )}
    </div>
  );
};
