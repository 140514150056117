import { Text } from "@fluentui/react";
import { Add16Regular } from "@fluentui/react-icons";

import styles from "./NewChatButton.module.css";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
}

export const NewChatButton = ({ className, disabled, onClick }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ""}`} onClick={onClick}>
            <a className={`${styles.link} ${className ?? ""} ${disabled && styles.disabled}`}>
                <Add16Regular />
                <Text>新しいチャット</Text>
            </a>
        </div>
    );
};
