import { useMsal } from "@azure/msal-react";
import { Avatar, FluentProvider, webDarkTheme } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";

import styles from "./UserChatMessage.module.css";

interface Props {
    message: string;
}

const MyAvatar = () => {
    const { accounts } = useMsal();
    const name = (accounts && accounts[0] && accounts[0].name) ?? "";
    return (
        <div className={styles.roleUserIcon}>
            <Avatar className={styles.roleUserAvator} name={name} shape="circular" />
        </div>
    );
};

export const UserChatMessage = ({ message }: Props) => {
    return (
        <div className={styles.roleUser}>
            <Stack className={styles.roleUserContainer} verticalAlign="space-between">
                <Stack.Item>
                    <Stack horizontal horizontalAlign="space-between">
                        <MyAvatar />
                        <div className={styles.roleUserMessage}>{message}</div>
                    </Stack>
                </Stack.Item>
            </Stack>
        </div>
    );
};
