import { useEffect } from "react";
import styles from "./Signin.module.css";
import { Button } from "@fluentui/react-components";

const SignIn = () => {
    const signin = () => {
        location.reload();
    };

    return (
        <div className={styles.container}>
            <div className={styles.contents}>
                <div className={styles.logo}>
                    <img src="https://powergenai.jp/wp-content/themes/pg/images/pga-logo.png" alt="PowerGenAI logo" />
                </div>
                <div className={styles.note}>
                    <p>サインインしてからご利用ください。</p>
                </div>
                <div className={styles.buttons}>
                    <Button className={styles.settingsButton} onClick={signin} appearance="primary" shape="rounded" size="large">
                        サインイン
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
