import { useState, useEffect } from "react";
import { XLg } from "react-bootstrap-icons";
import { FluentProvider, Select, SelectProps, useId, webLightTheme } from "@fluentui/react-components";

import styles from "./HelpPane.module.css";
import userGuidePdf from "../../assets/pgai_chat_user_guide_v2_2.pdf";

interface Props {
    isOpen: boolean;
    onClick: () => void;
}

export const HelpPane = ({ isOpen, onClick }: Props) => {
    const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        console.log(e.target.value);
    };

    const selectId = useId();

    const [value, setValue] = useState("");

    const onChange: SelectProps["onChange"] = (event, data) => {
        setValue(data.value);
    };

    return (
        <section className={`${styles.helpBlock} ${styles.sandBox} ${isOpen ? styles.open : ""}`}>
            <div className={styles.closeBtn}>
                <XLg onClick={onClick} />
            </div>
            <div className={styles.container}>
                <h2>ご利用方法 </h2>
                <FluentProvider theme={webLightTheme} className={styles.selectWrapper}>
                    <Select id={selectId} onChange={onChange} value={value} className={styles.select}>
                        <option>選択してください</option>
                        <option value="1">初期設定</option>
                        <option value="2">操作マニュアル</option>
                    </Select>
                    <span className={styles.spacer}></span>
                </FluentProvider>
                <div className={styles.tabContent}>
                    {value === "1" && (
                        <div className={styles.tabPane}>
                            <h5>初期設定</h5>
                            <div className={styles.innerBox}>
                                <div className={styles.box}>
                                    <h6>カスタム指示の登録</h6>
                                    <div className={styles.text}>カスタム指示にはあらかじめご自分のロールなど登録することができます。</div>
                                </div>
                            </div>
                        </div>
                    )}
                    {value === "2" && (
                        <div className={styles.tabPane}>
                            <div className={styles.innerBox}>
                                <div className={styles.box}>
                                    <div className={styles.text}>下記リンク先のPDFファイルをご参照ください。</div>
                                    <a href={userGuidePdf} target="_blank">
                                        操作マニュアル
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};
