import { LogLevel } from "@azure/msal-browser";

export const b2cPolicies = {
    names: {
        signIn: ""
    },
    authorities: {
        signIn: {
            authority: ""
        }
    },
    authorityDomain: ""
};

export const msalConfigB2C = {
    auth: {
        clientId: "",
        authority: "",
        knownAuthorities: [""],
        redirectUri: "",
        postLogoutRedirectUri: "",
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    },
    system: {
        loggerOptions: {
            logLevel: LogLevel.Info,
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};
