import { Image } from "@fluentui/react-components";
import React from "react";
import styles from "./LoadingIndicator.module.css";
import typingBalls from "../../assets/typing-balls-light.svg";

export const LoadingIndicator: React.FC = () => {
    return (
        <div className={styles.container}>
            <Image role="presentation" className={styles.image} src={typingBalls} />
        </div>
    );
};
