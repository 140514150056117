export const THREAD_LIST = {
    INITIAL_NUM: 40,
    LIMIT: 10
};

export const ANSWER_EVALUATE = {
    LIKE: 1,
    DISLIKE: 2
};

export const AI_MODEL_NAME = {
    GPT_3_5: "GPT-3.5",
    GPT_4: "GPT-4"
};

export const AI_MODEL_VALUE = {
    GPT_3_5: "gpt-35",
    GPT_4: "gpt-4"
};

export const MENU = {
    CUSTOM_INSTRUCTIONS: "カスタム指示設定",
    CLEAR_CONVERSATIONS: "チャット履歴を全て削除する",
    SETTINGS: "設定",
    SIGN_OUT: "サインアウト"
};

export const DEFAULT_THREAD_TITLE = "新しいチャット";
export const TEXTAREA_MAX_NUM_CHARACTERS = 1500;
export const THREAD_TITLE_MAX_NUM_CHARACTERS = 24;
export const USE_THIS_PROMPT = "利用する";

export const CSS_PROPS = {
    QUESTION_INPUT_TEXT_AREA: {
        MIN_HEIGHT: 56,
        MAX_HEIGHT: 300
    }
};
