import { useState, useEffect } from "react";
import { X, XLg } from "react-bootstrap-icons";

import styles from "./Infomation.module.css";
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, AccordionToggleEventHandler } from "@fluentui/react-components";
import { ChevronDown16Regular, ChevronUp16Regular } from "@fluentui/react-icons";
import { InformationGetResponse, getInformationApi } from "../../api";

interface Props {
    isOpen: boolean;
    onClick: () => void;
}

export const InformationPane = ({ isOpen, onClick }: Props) => {
    const [openItems, setOpenItems] = useState("0");

    const handleToggle: AccordionToggleEventHandler = (event, data) => {
        if (data.value === openItems) {
            setOpenItems("0");
        } else {
            setOpenItems(data.value as string);
        }
    };

    const callApiGetInformations = async () => {
        try {
            const result = await getInformationApi();
            const response: InformationGetResponse = {
                status: result.status,
                error: result.error ?? "",
                informations: result.informations ?? []
            };
            return response.informations;
        } catch (e) {
            alert(e);
        } finally {
            //NOP
        }
    };

    interface Information {
        id: string;
        title: string;
        content: string;
        updated_at: string;
    }

    const [informations, setInformations] = useState<Information[]>([]);

    useEffect(() => {
        const fetchInformations = async () => {
            const promptLibraries = (await callApiGetInformations()) ?? [];
            setInformations(promptLibraries);
        };
        fetchInformations();
    }, []);

    return (
        <section className={`${styles.infoBlock} ${styles.sandBox} ${isOpen ? styles.open : ""}`}>
            <div className={styles.closeBtn}>
                <XLg onClick={onClick} />
            </div>
            <div className={styles.container}>
                <h2>お知らせ</h2>
                <Accordion className={styles.accordion} multiple={true} collapsible={true} onToggle={handleToggle}>
                    {informations.map((information, index) => (
                        <AccordionItem value={information.id} className={styles.accordionItem} key={index}>
                            <AccordionHeader
                                className={`${styles.accordionHeader} ${openItems === information.id ? styles.expanded : styles.collapsed}`}
                                expandIconPosition="end"
                                expandIcon={openItems === information.id ? <ChevronUp16Regular /> : <ChevronDown16Regular />}
                            >
                                {information.title}
                                <span className={styles.badge}>{information.updated_at}</span>
                            </AccordionHeader>
                            <AccordionPanel className={styles.accordionPanel}>{information.content}</AccordionPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
            </div>
        </section>
    );
};
