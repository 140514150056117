import { FormEvent } from "react";
import {
    Button,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    FluentProvider,
    webLightTheme
} from "@fluentui/react-components";

import styles from "./DialogClearChatHistory.module.css";

interface Props {
    setIsOpen: (isOpen: boolean) => void;
    onClickClearChatHistory: () => void;
}

export const DialogClearChatHistory = ({ setIsOpen, onClickClearChatHistory }: Props) => {
    const handleSubmit = async (ev: FormEvent) => {
        ev.preventDefault();
        onClickClearChatHistory();
        setIsOpen(false);
    };

    return (
        <div className={styles.dialogWrapper}>
            <FluentProvider className={styles.provider} theme={webLightTheme}>
                <DialogSurface className={styles.dialogSurface}>
                    <form onSubmit={handleSubmit}>
                        <DialogBody className={styles.dialogBody}>
                            <DialogTitle>すべてのチャット履歴を削除します。本当によろしいですか？</DialogTitle>
                            <DialogContent></DialogContent>

                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="secondary">キャンセル</Button>
                                </DialogTrigger>
                                <Button type="submit" appearance="primary">
                                    削除する
                                </Button>
                            </DialogActions>
                        </DialogBody>
                    </form>
                </DialogSurface>
            </FluentProvider>
        </div>
    );
};
