import { useEffect } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { EventType, PublicClientApplication } from "@azure/msal-browser";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";
import SignIn from "./pages/signin/Signin";

interface PagesProps {
    environment: any;
}

const Pages = ({ environment }: PagesProps) => {
    const { instance } = useMsal();

    useEffect(() => {
        const callbackId = instance.addEventCallback(
            (event: {
                eventType: EventType;
                payload: { account: any; idTokenClaims: { [x: string]: any; oid: string | undefined; sub: string | undefined } };
                error: { errorMessage: string | string[] };
            }) => {
                if (
                    (event.eventType === EventType.LOGIN_SUCCESS ||
                        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
                    event.payload.account
                ) {
                    instance.setActiveAccount(event.payload.account);
                }
            }
        );

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, [instance]);

    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Chat environment={environment} />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </HashRouter>
    );
};

interface AppProps {
    instance: PublicClientApplication;
    environment: any;
}

const App = ({ instance, environment }: AppProps) => {
    return (
        <MsalProvider instance={instance}>
            <AuthenticatedTemplate>
                <Pages environment={environment} />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <SignIn />
            </UnauthenticatedTemplate>
        </MsalProvider>
    );
};

export default App;
