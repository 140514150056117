import { Avatar } from "@fluentui/react-components";

import styles from "./Answer.module.css";

export const AnswerIcon = () => {
    const backGroundColor = "";

    return (
        <div className={styles.answerIcon}>
            <Avatar className={styles.answerAvatar} name="A P" />
        </div>
    );
};
