import { Button, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Field, FluentProvider, webLightTheme } from "@fluentui/react-components";
import { Settings20Regular } from "@fluentui/react-icons";

import * as CONST from "../../utils/constant";
import styles from "./DialogSetting.module.css";

interface Props {
    setIsOpen: (isOpen: boolean) => void;
    setIsOpenNext: (isOpen: boolean) => void;
    threadLength: number;
}

export const DialogSetting = ({ setIsOpen, setIsOpenNext, threadLength }: Props) => {
    const handleClear = async () => {
        setIsOpen(false);
        setIsOpenNext(true);
    };

    return (
        <div className={styles.dialogWrapper}>
            <FluentProvider className={styles.provider} theme={webLightTheme}>
                <DialogSurface className={styles.dialogSurface}>
                    <DialogBody className={styles.dialogBody}>
                        <div className={styles.dialogTitle}>
                            <DialogTitle>{CONST.MENU.SETTINGS}</DialogTitle>
                        </div>
                        <DialogContent className={styles.dialogContents}>
                            <div className={styles.contentLeft}>
                                <div className={styles.category}>
                                    <Settings20Regular className={styles.categoryIcon} />
                                    <span className={styles.categoryTitle}>一般</span>
                                </div>
                            </div>
                            <div className={styles.contentRight}>
                                <DialogActions className={styles.content}>
                                    <div className={styles.label}>
                                        <Field label={CONST.MENU.CLEAR_CONVERSATIONS}></Field>
                                    </div>
                                    <div className={styles.item}>
                                        {threadLength > 0 ? (
                                            <Button type="button" appearance="primary" onClick={handleClear}>
                                                削除する
                                            </Button>
                                        ) : (
                                            <Button disabledFocusable>削除する</Button>
                                        )}
                                    </div>
                                </DialogActions>
                            </div>
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </FluentProvider>
        </div>
    );
};
