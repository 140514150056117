import { LoginHistoryUpdateRequest, LoginHistoryUpdateResponse, loginHistorynUpdateApi } from "../api";

export const updateLoginHistory = async (userId: string, userPrincipalName: string) => {
    try {
        const request: LoginHistoryUpdateRequest = {
            userId: userId,
            userPrincipalName: userPrincipalName
        };
        const result = await loginHistorynUpdateApi(request);
        const threadDetail: LoginHistoryUpdateResponse = {
            status: result.status,
            error: result.error ?? ""
        };
        return threadDetail;
    } catch (e) {
        //NOP
    } finally {
        //NOP
    }
};
