import { useEffect, useState } from "react";
import { Radio, RadioGroup } from "@fluentui/react-components";

import styles from "./DataTypeToggle.module.css";

interface Props {
    environment: any;
    dataTypeRef: React.MutableRefObject<string>;
}

export const DataTypeToggle = ({ environment, dataTypeRef }: Props) => {
    const [dataType, setDataType] = useState<string>(dataTypeRef.current ?? environment.temporaryFeature2 ? "1" : "1");

    const handleChange = (e: any, data: any) => {
        dataTypeRef.current = data.value || "regular";
        setDataType(data.value);
    };

    const log_debug = (datetime: string, message: string) => {
        if (environment.consoleLogOutput) console.debug(datetime, message);
    };

    useEffect(() => {
        const searchType = environment.temporaryFeature2 ? "1" : "1";
        setDataType(searchType);
        dataTypeRef.current = searchType;
        log_debug(new Date().toISOString(), `dataType1: ${dataTypeRef.current}`);
    }, []);

    useEffect(() => {
        setDataType(dataTypeRef.current);
        log_debug(new Date().toISOString(), `dataType2: ${dataTypeRef.current}`);
    }, [dataTypeRef.current]);

    return (
        <div className={styles.dataTypeToggle}>
            <RadioGroup className={styles.radioGroup} value={dataType} layout="horizontal" onChange={handleChange} aria-labelledby="">
                <Radio className={styles.type1} value="1" label="" />
                <Radio className={styles.type2} value="2" label="" />
            </RadioGroup>
            <div className={`${styles.circleSwith} ${dataType === "1" ? styles.circleLeft : styles.circleRight}`}></div>
        </div>
    );
};
