import React from "react";
import { useRef, useState } from "react";
import { Button } from "@fluentui/react-components";
import { Clipboard16Regular, ClipboardCheckmark16Regular } from "@fluentui/react-icons";

import styles from "./CodeCopyButton.module.css";

interface Props {
    children: React.ReactNode;
    lang: string;
    code: string;
}

export const CodeCopyButton = ({ lang, code }: Props) => {
    const inProgressRef = useRef<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const onClickClipboard = async () => {
        try {
            inProgressRef.current = true;
            setIsDisabled(inProgressRef.current);
            await navigator.clipboard.writeText(code).then(async () => {
                await new Promise(resolve => setTimeout(resolve, 1000));
            });
        } catch (err) {
            console.error("Failed to copy answer: ", err);
        } finally {
            inProgressRef.current = false;
            setIsDisabled(inProgressRef.current);
        }
    };

    return (
        <div className={`codeHeader ${styles.codeHeader}`}>
            <div className={styles.codeLanguage}>
                <div>{lang}</div>
            </div>
            <div className={styles.codeCopyButton} onClick={onClickClipboard}>
                <>
                    {!inProgressRef.current ? (
                        <Button
                            className={styles.button}
                            icon={<Clipboard16Regular />}
                            onClick={() => {
                                onClickClipboard();
                            }}
                            disabled={isDisabled}
                        ></Button>
                    ) : (
                        <Button className={styles.button} icon={<ClipboardCheckmark16Regular />}></Button>
                    )}
                    <span>Copy code</span>
                </>
            </div>
        </div>
    );
};
